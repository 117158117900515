import {observable, action, runInAction} from 'mobx';

import {formatMessage} from '~/intlProvider';

import {captureException} from '~/utils';
import observedAction from '~/utils/observedAction';

import {
  signIn,
  signOut,
  checkAuth,
  forgotPassword,
  resetPassword,
  activatePasswordToken,
} from '~/api/auth';

import {EXPIRE_TOKEN} from '~/constants';

import {NoticeProps, Notification} from 'pds';

import {appStore, notificationsStore} from './';
import {resetApp} from '~/utils/resetApp';

export class AuthStore {
  @observable isAuthenticated: boolean = false;
  @observable isChecked: boolean = false;

  @observable passwordTokenExpired: boolean = false;

  @observable.shallow errors: NoticeProps[] = [];
  @observable.ref errorsRef: Notification = null;

  @observable loading: boolean = false;

  @action.bound
  @observedAction
  async signIn(credentials: { email: string; password: string; remember: boolean; }, notifyReset?: boolean) {
    this.loading = true;
    appStore.reset();

    try {
      const {expires} = await signIn(credentials);

      const storage = credentials.remember ? localStorage : sessionStorage;
      storage.setItem(EXPIRE_TOKEN, expires);

      runInAction('signIn success', async () => {
        await this.checkAuth();
        this.loading = false;

        if (notifyReset) {
          notificationsStore.add({
            type: 'success',
            id: 'reset-success',
            message: formatMessage({id: 'app.forgot_password.reset_success'}),
          });
        }
      });
    } catch (error) {
      runInAction('signIn fail', () => {
        if (error.response && error.response.status === 401) {
          this.addError({
            type: 'warning',
            message: formatMessage({id: 'app.signin.wrong_email_or_password'}),
          });
        } else if (error.response && error.response.status === 403) {
          this.addError({
            type: 'warning',
            message: formatMessage({id: 'app.signin.account_locked'}),
          });
        } else {
          this.addError({
            type: 'error',
            message: formatMessage({id: 'app.signin.login_error'}),
          });
        }
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async signOut(checkAuth: boolean = true) {
    try {
      if (checkAuth) {
        await this.checkAuth();
      }

      if (this.isAuthenticated) {
        await signOut();
      }

      runInAction('signOut success', () => {
        this.isAuthenticated = false;
        this.isChecked = true;
        this.errors = [];

        resetApp();
      });
    } catch (error) {
      runInAction('signOut fail', () => {
        if (error.response && error.response.status === 401) {
          this.isAuthenticated = false;
          this.isChecked = true;

          resetApp();
        }
      });

      captureException(error);
    }
  }

  @action.bound
  @observedAction
  async checkAuth() {
    try {
      const isLogged = await checkAuth();
      if (isLogged) {
        // check expire token exists. if not, logout
        const expires = localStorage.getItem(EXPIRE_TOKEN) || sessionStorage.getItem(EXPIRE_TOKEN);
        if (!expires) {
          return this.signOut(false);
        }
      }

      runInAction('checkAuth success', () => {
        this.isAuthenticated = isLogged;
        this.isChecked = true;
      });
    } catch (error) {
      runInAction('checkAuth fail', () => {
        this.isChecked = true;
      });

      if (error.response && error.response.status === 401) {
        return;
      }

      captureException(error);
    }
  }

  @action.bound
  @observedAction
  async forgotPassword(email: string) {
    this.loading = true;
    try {
      await forgotPassword(email);

      runInAction('forgotPassword success', () => {
        this.loading = false;
        this.addError({
          type: 'success',
          message: formatMessage({id: 'app.signin.forgot_password_success'}),
        });
      });
    } catch (error) {
      runInAction('forgotPassword fail', () => {
        if (error.response && error.response.status === 404) {
          this.addError({
            type: 'warning',
            message: formatMessage({id: 'app.signin.user_not_found'}),
          });
        } else {
          this.addError({
            type: 'error',
            message: formatMessage({id: 'app.signin.forgot_password_error'}),
          });
        }
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async activatePasswordToken(token: string) {
    this.loading = true;
    try {
      await activatePasswordToken(token);

      runInAction('activatePasswordToken success', () => {
        this.loading = false;
      });
    } catch (error) {
      runInAction('activatePasswordToken fail', () => {
        if (error.response && error.response.status === 404) {
          this.passwordTokenExpired = true;
        } else {
          notificationsStore.add({
            type: 'error',
            message: formatMessage({id: 'app.signin.password_token_error'}),
          });
        }
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async resetPassword(credencials: {
    token: string;
    newPassword: string;
    repeatedNewPassword: string;
  }) {
    this.loading = true;
    try {
      const email = await resetPassword(credencials);
      await this.signIn({email, password: credencials.newPassword, remember: true}, true);

      runInAction('resetPassword success', () => {
        this.loading = false;
      });
    } catch (error) {
      runInAction('resetPassword fail', () => {
        if (error.response && error.response.status === 401) {
          this.passwordTokenExpired = true;
        } else if (error.response && error.response.status === 422) {
          notificationsStore.add({
            type: 'warning',
            message: formatMessage({id: 'app.profile.passwords_different'}),
          });
        } else {
          notificationsStore.add({
            type: 'error',
            message: formatMessage({id: 'app.forgot_password.reset_error'}),
          });
        }
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  stopLoading = () => {
    this.loading = false;
  }

  @action.bound
  setErrorsRef(ref: Notification) {
    this.errorsRef = ref;
  }

  @action.bound
  @observedAction
  addError(error: NoticeProps) {
    error.id = error.message;
    if (!this.errors.find(({id}) => error.id === id)) {
      error.onClose = this.removeError;
      this.errors.push(error);
      this.errorsRef.add(error);
    }
  }

  @action.bound
  @observedAction
  removeError(id: string | number) {
    this.errors = this.errors.filter(error => error.id !== id);
    this.errorsRef.remove(id);
  }

  @action.bound
  @observedAction
  clearErrors() {
    this.errors = [];
    if (this.errorsRef) {
      this.errorsRef.clear();
    }
  }

  @action.bound
  @observedAction
  reset() {
    this.clearErrors();
  }
}
