import { AxiosResponse } from 'axios';
import client from './httpClient';

interface fetchCampaigns {
  status?: Status[];
  campaign_ids?: number[];
  client_ids?: number[];
  fields?: string[];
  relative_period?: RelativePeriod;
  date_from?: ISODate;
  date_to?: ISODate;
  network: Network;
}

export function fetchCampaigns({
  status,
  campaign_ids,
  client_ids,
  fields,
  relative_period,
  date_from,
  date_to,
  network,
}: fetchCampaigns) {
  let options: Partial<fetchCampaigns> = {
    client_ids,
    fields,
  };

  if (campaign_ids && campaign_ids.length) {
    options.campaign_ids = campaign_ids;
  } else {
    options.status = status;
  }

  if (relative_period === 'custom') {
    options = { ...options, date_from, date_to };
  } else if (relative_period === 'all_time') {
    // options = { ...options };
  } else {
    options = { ...options, relative_period };
  }

  return client.post(`/${network}/campaigns`, options);
}

export function fetchCampaignsByShape(network: Network, shape: IShape) {
  const { dates, campaignIds } = shape.body;
  const { status } = shape.body[shape.body.mode];
  const fields = shape.body[shape.body.mode].fields
    .filter(field => !field.local)
    .map(field => field.path)
    // Load hourly fast stat lazy
    .filter(field => field !== 'statistics.general.hourly');

  return new Promise<Campaign[]>((resolve, reject) => fetchCampaigns({
    network,
    fields,
    relative_period: dates.title,
    date_from: dates.startDate,
    date_to: dates.endDate,
    campaign_ids: campaignIds,
    status,
  })
    .then(res => resolve(res.data.result.campaigns))
    .catch(error => reject(error))
  );
}

export function getCampaignBudgetInfo(network: Network, campaign: number) {
  return new Promise<{}>((resolve, reject) => client.post(`/${network}/budget/info`, {
    campaign_id: campaign,
  })
    .then((res: AxiosResponse<{ budget: {} }>) => resolve(res.data.budget))
    .catch(error => reject(error))
  );
}

export function getCampaignRateInfo(network: Network, campaign: number) {
  return new Promise<{}>((resolve, reject) => client.post(`/${network}/bid/info`, {
    campaign_id: campaign,
  })
    .then((res: AxiosResponse<{ bid: {} }>) => resolve(res.data.bid))
    .catch(error => reject(error))
  );
}

export function getCampaignsInfo(network: Network, campaigns: number[]) {
  return new Promise<Campaign[]>((resolve, reject) => client.post(`${network}/campaigns/info`, {
    campaign_ids: campaigns,
  })
    .then((res: AxiosResponse<{ result: { campaigns: Campaign[] } }>) => resolve(res.data.result.campaigns))
    .catch(error => reject(error))
  );
}

export function getCampaignTargetings(network: Network, campaigns: number[]) {
  return new Promise<Campaign[]>((resolve, reject) => client.post(`${network}/campaigns/targetings/info`, {
    campaign_ids: campaigns,
  })
    .then((res: AxiosResponse<{ result: { campaigns: Campaign[] } }>) => resolve(res.data.result.campaigns))
    .catch(error => reject(error))
  );
}

export function updateCampaigns(network: Network, campaigns: Campaign[]) {
  return new Promise<number[]>((resolve, reject) => client.post('/rpc', {
    command: `rpc.${network}.campaigns.update`,
    data: campaigns,
  })
    .then((res: AxiosResponse<{ ok: string[] }>) => {
      if (res.data.ok) {
        resolve(res.data.ok.map(el => Number(el)));
      } else {
        reject(res.data);
      }
    })
    .catch(error => reject(error))
  );
}

export function updateCampaignsAsync(network: Network, campaigns: Campaign[]) {
  return new Promise<string[]>((resolve, reject) => client.post('/rpc', {
    command: `rpc.${network}.campaigns.update`,
    data: campaigns
  })
    .then((res: AxiosResponse<{ job_id: string[] }>) => {
      resolve(res.data.job_id);
    })
    .catch(error => reject(error))
  );
}
