import client from './httpClient';

export const fetchPayers = () => {
  return new Promise<PaymentPayer[]>((resolve, reject) =>
    client.post('/cabinet/payers')
    .then((res) => {
      if (res.data.success) {
        resolve(res.data.result.payers);
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error))
  );
};

export const fetchUsers = () => {
  return new Promise<PaymentUser[]>((resolve, reject) =>
    client.post('/cabinet/users')
    .then((res) => {
      if (res.data.success) {
        resolve(res.data.result.users);
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error))
  );
};

export const addUser = (plarin_id: string) => {
  return new Promise((resolve, reject) =>
    client.post('/cabinet/users/add', { plarin_id })
    .then(() => {
      resolve();
    })
    .catch(error => reject(error))
  );
};
