import { observable, action, runInAction, ObservableMap } from 'mobx';

import observedAction from '~/utils/observedAction';
import { captureException } from '~/utils';

import { fetchClients } from '~/api/clients';
import { appStore } from './';

const MOBILE_OS_SORT: MobileOS[] = [
  'iOS',
  'Android',
  'Windows Phone',
];

const CURRENCY_SORT: Currency[] = [
  'RUB',
  'USD',
  'EUR',
];

export class ClientsStore {
  @observable.shallow collection: ObservableMap<number, Client>;
  @observable loading: boolean = true;

  @observable.shallow filteredCollection: ObservableMap<number, Client>;

  @observable currencyList: Currency[] = [];
  @observable mobileOSList: MobileOS[] = [];
  @observable platformList: Platform[] = [];

  @observable selectedCurrencyList: Currency[] = [];
  @observable selectedMobileOSList: MobileOS[] = [];
  @observable selectedPlatformList: Platform[] = [];

  constructor() {
    this.collection = observable.map(new Map());
    this.filteredCollection = observable.map(new Map());
  }

  @action.bound
  @observedAction
  reset() {
    this.collection.clear();
    this.filteredCollection.clear();
    this.loading = true;

    this.currencyList = [];
    this.mobileOSList = [];
    this.platformList = [];
    this.selectedCurrencyList = [];
    this.selectedMobileOSList = [];
    this.selectedPlatformList = [];
  }

  @action.bound
  @observedAction
  async loadClients(statuses?: Status[]) {
    this.loading = true;
    try {
      const clients = await fetchClients(appStore.network, statuses);
      runInAction('loadClients success', () => {
        clients.forEach(client => this.collection.set(client.id, client));

        const clientsCampaigns = this.getCampaigns(clients);

        this.currencyList = this.getCurrencyList(clients);
        this.platformList = this.getPlatformList(clientsCampaigns);
        this.mobileOSList = this.getMobileOSList(clientsCampaigns);

        this.selectedCurrencyList = this.currencyList;
        this.selectedMobileOSList = this.mobileOSList;
        this.selectedPlatformList = this.platformList;

        this.filterClients();

        this.loading = false;
      });
    } catch (error) {
      runInAction('loadClients fail', () => {
        this.collection.clear();
        this.loading = false;
      });
      captureException(error);
    }
  }

  @action.bound
  @observedAction
  updateSelectedCurrencyList(values: Currency[]): Promise<null> {
    return new Promise((resolve) => {
      this.selectedCurrencyList = values;
      this.filterClients();
      resolve();
    });
  }

  @action.bound
  @observedAction
  updateSelectedMobileOSList(values: MobileOS[]): Promise<null> {
    return new Promise((resolve) => {
      this.selectedMobileOSList = values;
      this.filterClients();
      resolve();
    });
  }

  @action.bound
  @observedAction
  updateSelectedPlatformList(values: Platform[]): Promise<null> {
    return new Promise((resolve) => {
      this.selectedPlatformList = values;
      this.filterClients();
      resolve();
    });
  }

  @action.bound
  @observedAction
  filterClients() {
    const hasDifferentCurrencies = this.currencyList.length > 0;
    const hasDifferentMobileOS = this.mobileOSList.length > 0;
    const hadDifferentPlatforms = this.platformList.length > 0;

    this.filteredCollection.clear();

    for (const entry of this.collection) {
      const client = entry[1];

      if (['agency', 'manager'].includes(client.type)) {
        continue;
      }

      if (client.campaigns.length === 0) {
        continue;
      }

      /* [PLUI-95] Если в списке клиентов микс валют, то показывать пикер валют и фильтровать по ним */
      if (hasDifferentCurrencies && !this.selectedCurrencyList.includes(client.currency)) {
        continue;
      }

      let campaigns: ClientCampaign[] = client.campaigns;

      if (hasDifferentMobileOS) {
        campaigns = campaigns.filter((campaign: ClientCampaign) => (
          !campaign.mobile_os || this.selectedMobileOSList.includes(campaign.mobile_os)
        ));
      }

      if (hadDifferentPlatforms) {
        campaigns = campaigns.filter((campaign: ClientCampaign) => (
          !campaign.type || this.selectedPlatformList.includes(campaign.type)
        ));
      }

      if (campaigns.length) {
        this.filteredCollection.set(client.id, { ...client, campaigns });
      }
    }
  }

  getCampaigns(clients: Client[]): ClientCampaign[] {
    const clientsCampaigns: ClientCampaign[] = [];

    clients.forEach(client => clientsCampaigns.push(...client.campaigns));

    return clientsCampaigns;
  }

  getPlatformList(campaigns: ClientCampaign[]): Platform[] {
    if (!campaigns || campaigns.length === 0) {
      return [];
    }

    const types: Platform[] = [];

    campaigns.forEach((campaign: ClientCampaign) => {
      if (!types.includes(campaign.type)) {
        types.push(campaign.type);
      }
    });

    return types;
  }

  getMobileOSList(campaigns: ClientCampaign[]): MobileOS[] {
    if (!campaigns || campaigns.length === 0) {
      return [];
    }

    const osList: MobileOS[] = [];

    campaigns.forEach((campaign: ClientCampaign) => {
      if (campaign.mobile_os && !osList.includes(campaign.mobile_os)) {
        osList.push(campaign.mobile_os);
      }
    });

    return osList.sort((a: MobileOS, b: MobileOS) => (
      MOBILE_OS_SORT.indexOf(a) - MOBILE_OS_SORT.indexOf(b)
    ));
  }

  getCurrencyList(clients: Client[]): Currency[] {
    if (!clients || clients.length === 0) {
      return [];
    }

    const currencies: Currency[] = [];

    clients.forEach((client) => {
      if (!currencies.includes(client.currency)) {
        currencies.push(client.currency);
      }
    });

    return currencies.sort((a: Currency, b: Currency) => (
      CURRENCY_SORT.indexOf(a) - CURRENCY_SORT.indexOf(b)
    ));
  }
}
