import React, { Component, FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import queryString from 'query-string';
import H from 'history';
import { Row, Col, Button, Password, PasswordProps, Logo } from 'pds';
import { formatMessage } from '~/intlProvider';
import { AuthStore } from '~/stores/authStore';
import {getPasswordAcceptableSymbols} from '~/utils';
import messages from './messages';
import './styles.css';

interface IProps {
  authStore?: AuthStore;
  location: {
    search: string;
  };
  history: H.History;
}

interface IState {
  newPassword: string;
  repeatedNewPassword: string;
  passwordRules: PasswordProps['rules'];
  errors: {
    newPassword: boolean;
    repeatedNewPassword?: boolean;
  };
  tokenActivated: boolean;
  token: string;
}

@inject('authStore')
@observer
export default class ForgotPassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const token = queryString.parse(props.location.search).token as string;
    this.state = {
      newPassword: '',
      repeatedNewPassword: '',
      passwordRules: [{
        label: formatMessage({ id: 'app.profile.password_rule_uppercase' }, { count: 1 }),
      }, {
        label: formatMessage({ id: 'app.profile.password_rule_number' }, { count: 1 }),
      }, {
        label: formatMessage({ id: 'app.profile.password_rule_length' }, { count: 8 }),
      }],
      errors: { newPassword: false, repeatedNewPassword: false },
      tokenActivated: false,
      token,
    };

    props.authStore.activatePasswordToken(token)
      .then(() => {
        this.setState({ tokenActivated: true });
      })
      .catch(() => {
        props.authStore.stopLoading();
      });
  }

  @computed get passwordTokenExpired() {
    return this.props.authStore.passwordTokenExpired;
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { newPassword, repeatedNewPassword, token } = this.state;
    const errors = {
      newPassword: !newPassword.length || this.state.errors.newPassword,
      repeatedNewPassword: !repeatedNewPassword.length || newPassword !== repeatedNewPassword,
    };
    this.setState({ errors });

    if (Object.values(errors).includes(true)) {
      return;
    }

    this.props.authStore.resetPassword({ token, newPassword, repeatedNewPassword })
      .then(() => this.setState({ token: '' }))
      .catch(() => {});
  }

  handlePasswordChange = (value: string | number) => {
    const newPassword = value.toString();
    this.setState({ newPassword });
    this.validatePassword(newPassword);
  }

  handleRepeatedPasswordChange = (value: string | number) => {
    const repeatedNewPassword = value.toString();
    this.setState({ repeatedNewPassword });
    this.setState({ errors: { ...this.state.errors, repeatedNewPassword: false } });
  }

  handleInputBlur = () => {
    this.validatePassword(this.state.newPassword, true);
  }

  private validatePassword = (value: string | number, setErrors?: boolean): void => {
    const password = value.toString();

    if (!password.length) {
      this.setState(prevState => ({
        passwordRules: prevState.passwordRules.map((rule) => {
          delete rule.status;
          return rule;
        }),
        errors: { ...prevState.errors, newPassword: false },
      }));
    } else {
      const tests = [
        /[A-Z]/.test(password),
        password.match(/[0-9]/g),
        password.length >= 8,
      ];

      this.setState((prevState) => {
        const passwordRules = prevState.passwordRules.map((rule, index) => {
          if (setErrors) {
            rule.status = tests[index] ? 'success' : 'error';
          } else if (tests[index]) {
            rule.status = 'success';
          }
          return rule;
        });
        const errors = prevState.errors;
        if (setErrors) {
          errors.newPassword = tests.includes(false);
        } else if (!tests.includes(false)) {
          errors.newPassword = false;
        }
        return { passwordRules, errors };
      });
    }
  }

  render() {
    const { errors, token, passwordRules, newPassword, repeatedNewPassword, tokenActivated } = this.state;
    const passwordCharsValid = newPassword ? getPasswordAcceptableSymbols().test(newPassword) : true;
    const submitDisabled = (
      !newPassword.length
      || !repeatedNewPassword.length
      || !passwordCharsValid
      || errors.newPassword
      || errors.repeatedNewPassword
      || this.props.authStore.loading
    );

    if (!token || !token.length || this.passwordTokenExpired) {
      return (
        <Redirect to="/login" />
      );
    }
    if (!tokenActivated) {
      return null;
    }

    return (
      <Row className="forgot">
        <Col className="forgot-modal" span={12}>
          <div className="forgot-modal-group">
            <div className="forgot-form">
              <Logo className="logo"></Logo>
              <h4>{formatMessage(messages.title)}</h4>
              <form onSubmit={this.handleSubmit} className="modal-form">
                <Row margin="xl">
                  <Col span={12}>
                    <Password
                      label={formatMessage({ id: 'app.profile.new_password' })}
                      onChange={this.handlePasswordChange}
                      onBlur={this.handleInputBlur}
                      rules={passwordRules}
                      error={errors.newPassword || !passwordCharsValid}
                      errorMessage={passwordCharsValid
                        ? formatMessage({ id: 'app.profile.new_password_required' })
                        : 'Пароль содержит недопустимые символы'}
                    />
                  </Col>
                </Row>
                <Row margin="xl">
                  <Col span={12}>
                    <Password
                      label={formatMessage({ id: 'app.profile.repeat_new_password' })}
                      onChange={this.handleRepeatedPasswordChange}
                      error={errors.repeatedNewPassword}
                      errorMessage={!repeatedNewPassword || !repeatedNewPassword.length
                        ? formatMessage({ id: 'app.profile.repeat_new_password' })
                        : 'Введенные пароли не совпадают'}
                    />
                  </Col>
                </Row>
                <Button
                  disabled={submitDisabled}
                  className="modal-button"
                  type="primary"
                  htmlType="submit"
                  stretch
                  loading={this.props.authStore.loading}
                >
                  {formatMessage(messages.submit)}
                </Button>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
