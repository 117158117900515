import { defineMessages } from 'react-intl';

import { formatMessage } from '../intlProvider';

import { campaignsFields, adsFields } from './defaultFields';
import getPresets, {defaultDatePresets, Preset} from './presets';

const definedMessages = defineMessages({
  defaultName: {
    id: 'app.new_tab.default_name',
    defaultMessage: 'Новая вкладка',
  },
});

const generateShape = (wizard: boolean = true): IShape => {
  const initialPreset = getPresets(defaultDatePresets, 'all_time') as Preset;

  return {
    name: formatMessage(definedMessages.defaultName),
    id: `temp_${Math.random().toString(36).substr(2, 9)}`,
    body: {
      campaignIds: [],
      dates: {
        title: initialPreset.id,
        startDate: initialPreset.startDate.format(),
        endDate: initialPreset.endDate.format(),
      },
      mode: 'campaigns',
      ads: {
        pivot: false,
        pivotOptions: {
          rows: [],
          columns: [],
          values: [],
        },
        fields: adsFields,
        grouping: '',
        status: [0, 1],
      },
      campaigns: {
        pivot: false,
        pivotOptions: {
          rows: [],
          columns: [],
          values: [],
        },
        fields: campaignsFields,
        grouping: '',
        status: [0, 1],
      },
    },
    unsaved: true,
    wizard,
    index: 0,
  };
};

export default generateShape;
