import { observable, action, runInAction } from 'mobx';
import observedAction from '~/utils/observedAction';

import { captureException } from '~/utils';

import { appStore } from './';

import fetchMetrics from '~/api/metrics';

export class MetricsStore {
  @observable.shallow ads: Metric[];
  @observable.shallow campaigns: Metric[];
  @observable loading: boolean = true;

  @action.bound
  @observedAction
  async loadMetrics() {
    this.loading = true;
    try {
      const metrics = await fetchMetrics(appStore.network);
      runInAction('loadMetrics success', () => {
        this.ads = metrics.ads;
        this.campaigns = metrics.campaigns;
        this.loading = false;
      });
      return metrics;
    } catch (error) {
      runInAction('loadMetrics fail', () => {
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }
}
