import { observable, action, runInAction } from 'mobx';

import observedAction from '~/utils/observedAction';
import { captureException } from '~/utils';

import { appStore } from './';

import fetchResources from '~/api/resources';

export class ResourcesStore {
  @observable.shallow mt: Resources['mt'];
  @observable.shallow base: Resources['base'];
  @observable loading: boolean = true;

  @action.bound
  @observedAction
  async fetchResources() {
    this.loading = true;
    try {
      const resources = await fetchResources(appStore.network);
      runInAction('fetchResources success', () => {
        this.mt = resources.mt;
        this.base = resources.base;
        this.loading = false;
      });
      return resources;
    } catch (error) {
      runInAction('fetchResources fail', () => {
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }
}
