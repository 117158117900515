import client from './httpClient';

interface IPromise {
  campaigns: Metric[];
  ads: Metric[];
}

const fetchMetrics = (network: Network) => {
  return new Promise<IPromise>((resolve, reject) => client.post('/user/metrics', { network })
    .then(res => resolve(res.data.metrics))
    .catch(error => reject(error))
  );
};

export default fetchMetrics;
