import { observable, action } from 'mobx';
import observedAction from '~/utils/observedAction';

import { Notification, NoticeProps } from 'pds';

export class NotificationsStore {
  @observable collection: NoticeProps[] = [];
  @observable.ref ref: Notification = null;

  @action.bound
  setRef(ref: Notification) {
    this.ref = ref;
    if (this.collection.length) {
      this.collection.forEach((notice) => {
        this.add(notice);
      });
    }
  }

  @action.bound
  @observedAction
  add(notice: NoticeProps) {
    notice.id = notice.id || notice.message;
    notice.onClose = () => {
      this.collection = this.collection.filter(({ id }) => notice.id !== id);
    };
    this.collection.push(notice);
    this.ref && this.ref.add(notice);
  }

  @action.bound
  @observedAction
  remove(id: string) {
    this.collection = this.collection.filter(notice => notice.id !== id);
    this.ref && this.ref.remove(id);
  }

  @action.bound
  @observedAction
  clear() {
    this.collection = [];
    this.ref && this.ref.clear();
  }

  @action.bound
  @observedAction
  reset() {
    this.ref = null;
    this.collection = [];
    this.ref && this.ref.clear();
  }
}
