import { observable, action, runInAction } from 'mobx';

import { captureException } from '~/utils';
import observedAction from '~/utils/observedAction';

import { fetchPayers, fetchUsers, addUser } from '~/api/organization';

import { notificationsStore } from './';

export class OrganizationStore {
  @observable.shallow payers: PaymentPayer[] = [];
  @observable.shallow users: PaymentUser[] = [];

  @observable loading: boolean = false;
  @observable payersLoading: boolean = false;

  @action.bound
  @observedAction
  async fetchPayers() {
    this.loading = true;
    this.payersLoading = true;
    try {
      const payers = await fetchPayers();
      runInAction('fetchPayers success', () => {
        this.payers = payers;

        this.payersLoading = false;
        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchPayers fail', () => {
        this.loading = false;
        this.payersLoading = false;
      });
      captureException(error);
    }
  }

  @action.bound
  @observedAction
  async fetchUsers() {
    this.loading = true;
    try {
      const users = await fetchUsers();
      runInAction('fetchUsers success', () => {
        this.users = users;
        this.users.forEach((user) => {
          const namesList = [user.lname, user.fname, user.sname];
          user.fullName = namesList.filter(_ => _).join(' ');
          user.roleLabel = user.role === 1 ? 'Администратор' : 'Пользователь';
          if (user.phone === '') {
            delete user.phone;
          } else if (user.phone) {
            user.phone = user.phone.toString();
          }
        });

        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchUsers fail', () => {
        this.loading = false;
      });
      console.error(error);
      captureException(error);
    }
  }

  @action.bound
  @observedAction
  async addUser(id: string) {
    this.loading = true;
    try {
      await addUser(id);
      runInAction('addUser success', () => {
        notificationsStore.add({
          type: 'success',
          message: 'Пользователю отправлено приглашение',
        });

        this.loading = false;
      });
    } catch (error) {
      runInAction('addUser fail', () => {
        if (error.response && error.response.status === 404) {
          notificationsStore.add({
            type: 'warning',
            message: `Пользователь ${id} не найден`,
          });
        } else if (error.response && error.response.status === 409) {
          notificationsStore.add({
            type: 'warning',
            message: `Приглашение для пользователя ${id} уже отправлено`,
          });
        } else if (error.response && error.response.status === 461) {
          notificationsStore.add({
            type: 'warning',
            message: `Пользователя ${id} уже подключен к кабинету`,
          });
        } else {
          notificationsStore.add({
            type: 'error',
            message: `Ошибка при отправлении приглашения пользователю ${id}`,
          });
        }

        this.loading = false;
      });
      captureException(error);
    }
  }

  @action.bound
  @observedAction
  reset() {
    this.payers = [];
    this.users = [];

    this.loading = false;
  }
}
