import { defineMessages } from 'react-intl';

const definedMessages = defineMessages({
  title: {
    id: 'app.signin.title',
    defaultMessage: 'Вход в платформу',
  },
  titleForgot: {
    id: 'app.signin.title_forgot',
    defaultMessage: 'Восстановление пароля',
  },
  instructionsForgot: {
    id: 'app.signin.instructions_forgot',
    defaultMessage: 'Для восстановления пароля укажите электронную почту, \
    указанную в вашей учетной записи в Plarin и нажмите «Восстановить». \
    Вам будет отправлено письмо с инструкцией по смене пароля.',
  },
  back: {
    id: 'app.signin.back',
    defaultMessage: 'Вернуться',
  },
  email: {
    id: 'app.signin.email',
    defaultMessage: 'Электронная почта',
  },
  emailRequired: {
    id: 'app.signin.email_required',
    defaultMessage: 'Введите корректную электронную почту',
  },
  password: {
    id: 'app.signin.password',
    defaultMessage: 'Пароль',
  },
  passwordRequired: {
    id: 'app.signin.password_required',
    defaultMessage: 'Введите пароль',
  },
  passwordForgot: {
    id: 'app.signin.password_forgot',
    defaultMessage: 'Забыли пароль?',
  },
  remember: {
    id: 'app.signin.remember',
    defaultMessage: 'Запомнить меня',
  },
  submit: {
    id: 'app.signin.submit',
    defaultMessage: 'Войти',
  },
  logout: {
    id: 'app.signin.logout',
    defaultMessage: 'Выйти',
  },
  recover: {
    id: 'app.signin.submit_recover',
    defaultMessage: 'Восстановить',
  },
  wrongEmailOrPassword: {
    id: 'app.signin.wrong_email_or_password',
    defaultMessage: 'Неправильная электронная почта или пароль',
  },
  accountLocked: {
    id: 'app.signin.account_locked',
    defaultMessage: 'Учетная запись пользователя заблокирована',
  },
  loginError: {
    id: 'app.signin.login_error',
    defaultMessage: 'Ошибка при входе в платформу',
  },
  userNotFound: {
    id: 'app.signin.user_not_found',
    defaultMessage: 'Пользователь с указанным адресом электронной почты не найден',
  },
  forgotPasswordError: {
    id: 'app.signin.forgot_password_error',
    defaultMessage: 'Ошибка при отправке письма для смены пароля',
  },
  forgotPasswordSuccess: {
    id: 'app.signin.forgot_password_success',
    defaultMessage: 'Письмо с инструкцией по смене пароля успешно отправлено',
  },
  passwordTokenError: {
    id: 'app.signin.password_token_error',
    defaultMessage: 'Ошибка активации ссылки для восстановления пароля',
  },
  passwordIncorrectCharsError: {
    id: 'app.signin.password_incorrect',
    defaultMessage: 'Пароль содержит недопустимые символы',
  },
});

export default definedMessages;
