import client from './httpClient';

export const fetchAccounts = () => {
  return new Promise<PaymentAccounts>((resolve, reject) =>
    client.post('/account/info')
      .then((res) => {
        if (res.data.success) {
          resolve({
            accounts: res.data.accounts,
            is_alternative_payments: res.data.is_alternative_payments,
          });
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchOrders = (req: PaymentOrdersRequest) => {
  return new Promise<{ orders: PaymentOrder[], total_count: number }>((resolve, reject) =>
    client.post('/account/orders', req)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.result);
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchClients = (network: Network, params: {
  relative_period?: RelativePeriod;
  date_from?: ISODate;
  date_to?: ISODate;
}) => {
  return new Promise<{
    clients: PaymentClient[Network][],
    stat: {
      [id: string]: {
        total: {
          clicks: number,
          impressions: number,
          spent: number,
        }
      }
    },
    terms?: {
      web?: number;
      mob?: number;
    }
  }>((resolve, reject) =>
    client.post(`/topup/${network}/clients`, params)
      .then((res) => {
        if (res.data.success) {
          resolve({clients: res.data.result.clients, stat: res.data.stat, terms: res.data.terms || {}});
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchClientsInfo = (network: Network, ids?: string[]) => {
  return new Promise<{
    managers: PaymentManager[Network][],
    clients: PaymentClient[Network][],
  }>((resolve, reject) =>
    client.post(`/topup/${network}/clients/info`, {client__ids: ids})
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.info);
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchSuppliers = (network: Network) => {
  return new Promise<PaymentSupplier[]>((resolve, reject) =>
    client.post(`/topup/${network}/suppliers`)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.result.suppliers);
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
}

export const fetchManagers = (network: Network) => {
  return new Promise<PaymentManager[Network][]>((resolve, reject) =>
    client.post(`/topup/${network}/managers`)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.result.managers);
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchManagersInfo = (network: Network, ids?: string[]) => {
  return new Promise<{
    managers?: PaymentManager[Network][],
    suppliers: PaymentSupplier[]
  }>((resolve, reject) =>
    client.post(`/topup/${network}/managers/info`, {manager__ids: ids})
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.info);
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchActionsInfo = (network: Network, ids: string[]) => {
  return new Promise<{
    accounts: PaymentAccounts,
    clients: PaymentClient[Network][],
    terms?: {web?: number, mob?: number},
    topup_open: number;
    refund_open: number;
  }>((resolve, reject) =>
    client.post(`/topup/${network}/info`, {client__ids: ids})
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.info);
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const fetchBalances = () => {
  return new Promise<PaymentBalances>((resolve, reject) =>
    client.post('/topup/balances')
      .then((res) => {
        delete res.data.success;
        resolve(res.data);
      })
      .catch(error => reject(error))
  );
};

export const topup = (network: Network, req: PaymentActionRequest[]) => {
  return new Promise<PaymentActionError[]>((resolve, reject) =>
    client.post(`/topup/${network}/action/topup`, req)
      .then(res => resolve(res.data.errors))
      .catch(error => reject(error))
  );
};

export const refund = (network: Network, req: PaymentActionRequest[]) => {
  return new Promise<PaymentActionError[]>((resolve, reject) =>
    client.post(`/topup/${network}/action/refund`, req)
      .then(res => resolve(res.data.errors))
      .catch(error => reject(error))
  );
};

export const cancel = (network: Network, cancelData: {
  order_id: string,
  description?: string;
}) => {
  return new Promise((resolve, reject) =>
    client.post(`/topup/${network}/action/cancel`, cancelData)
      .then((res) => {
        if (res.data.success || !res.data.errors) {
          resolve();
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      })
      .catch(error => reject(error))
  );
};

export const addManager = (network: Network, req: PaymentAddManagerRequest) => {
  return new Promise<PaymentAddManagerActionResult>((resolve, reject) =>
    client.post(`/topup/${network}/managers/add`, req)
      .then(res => resolve({errors: res.data.results, _id: res.data._id}))
      .catch(error => reject(error))
  );
};

export const editManager = (network: Network, req: PaymentAddManagerRequest) => {
  return new Promise<PaymentAddManagerActionResult>((resolve, reject) =>
    client.post(`/topup/${network}/managers/edit`, req)
      .then(res => resolve({errors: res.data.results}))
      .catch(error => reject(error))
  );
};

export const removeManagerClient = (network: Network, req: PaymentRemoveManagerClientRequest) => {
  return new Promise((resolve, reject) =>
    client.post(`/topup/${network}/managers/remove`, req)
      .then(() => resolve())
      .catch(error => reject(error))
  );
}

export const disconnectManager = (network: Network, req: PaymentDisconnectManagerRequest) => {
  return new Promise((resolve, reject) =>
    client.post(`/topup/${network}/managers/disconnect`, req)
      .then(res => resolve())
      .catch(error => reject(error))
  );
};

export const createClients = (network: Network, req: PaymentCreateClientsRequest) => {
  return new Promise<PaymentActionError[]>((resolve, reject) =>
    client.post(`/topup/${network}/clients/create`, req)
      .then(res => resolve(res.data.results))
      .catch(error => reject(error))
  );
};

export const editClients = (network: Network, req: PaymentEditClientsRequest) => {
  return new Promise<PaymentActionError[]>((resolve, reject) =>
    client.post(`/topup/${network}/clients/edit`, req)
      .then(res => resolve(res.data.results))
      .catch(error => reject(error))
  );
};
