import dayjs from 'dayjs';
import { observable, action, runInAction, ObservableMap } from 'mobx';

import observedAction from '~/utils/observedAction';
import { captureException } from '~/utils';

import {fetchStatement} from '~/api/statement';
import {operationTypes} from '~/pages/Core/payment/Statement/operation-types';
import {datePresets} from '~/pages/Core/payment/Statement/datePresets';

export interface IGridStatementItem {
  date: string;
  time: string;
  type: TTransacrionType;
  translatedType: string;
  comment: string;
  author: string;
  amount: number;
}

const {auto_refund, auto_topup, ...defaultOperationTypes} = operationTypes;

export class StatementStore {
  @observable.shallow transactions: ObservableMap<string, IGridStatementItem>;
  @observable loading: boolean = true;
  @observable balanceAmount: string;
  @observable balanceDt: string;
  @observable selectedTransacrionType: TTransacrionType[] = Object.keys(defaultOperationTypes) as TTransacrionType[];

  @observable dates: IShape['body']['dates'] = {
    title: datePresets[0].id,
    startDate: <any>datePresets[0].startDate,
    endDate: <any>datePresets[0].endDate,
  };

  constructor() {
    this.transactions = observable.map(new Map());
  }

  get exportFileName(): string {
    const defaultName = 'Billing account';
    const defaultExt = 'xlsx';
    if (this.dates['title'] === 'all_time') {
      return `${defaultName} - All time.${defaultExt}`;
    }
    else {
      const startDate = dayjs(this.dates['startDate']).format('DD.MM.YYYY');
      const endDate = dayjs(this.dates['endDate']).format('DD.MM.YYYY');
      return `${defaultName} - ${startDate} - ${endDate}.${defaultExt}`;
    }
  }

  @action.bound
  setSelectedTransacrionType(selectedTransacrionType: TTransacrionType[]) {
    this.selectedTransacrionType = selectedTransacrionType;
  }

  @action.bound
  @observedAction
  reset() {
    this.transactions.clear();
    this.loading = true;
  }

  @action.bound
  @observedAction
  changeDatePreset(title: RelativePeriod, startDate: ISODate, endDate: ISODate) {
    this.dates = {
      title,
      startDate,
      endDate,
    };
  }

  @action.bound
  @observedAction
  async fetchStatement() {
    this.loading = true;
    try {
      const reqParams = {
        type: this.selectedTransacrionType,
      };
      if (this.dates['title'] === 'custom') {
        reqParams['date_from'] = this.dates['startDate'];
        reqParams['date_to'] = this.dates['endDate'];
      }
      else if (this.dates['title'] !== 'all_time') {
        reqParams['relative_period'] = this.dates['title'];
      }
      const result: IStatementRespResult = await fetchStatement(reqParams);
      runInAction('load statement success', () => {
        this.transactions.clear();
        result.transactions.forEach((transaction) => {
          const {amount, type, _id, author, description, dt, is_online_replenish} = transaction;
          const date = dayjs(dt);
          this.transactions.set(_id, {
            type,
            amount,
            author,
            comment: type === 'replenish' && is_online_replenish ? `Форма оплаты: ${description}` : description,
            date: date.format('DD.MM.YYYY'),
            time: date.format('HH:mm:ss'),
            translatedType: operationTypes[type],
          });
        });
        this.balanceAmount = result.balance_amount.toString();
        this.balanceDt = result.balance_dt;
        this.loading = false;
      });
    } catch (error) {
      runInAction('loadClients fail', () => {
        this.transactions.clear();
        this.loading = false;
      });
      captureException(error);
    }
  }
}
