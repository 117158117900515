import client from './httpClient';

const fetchResources = (network: Network) => {
  return new Promise<Resources>((resolve, reject) => client.post('/resources', { network })
    .then(res => resolve(res.data.resources))
    .catch(error => reject(error))
  );
};

export default fetchResources;
