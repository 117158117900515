import findLastIndex from 'lodash.findlastindex';

export default (path: string, parent: string, fields: ShapeField[], metrics: Metric[]) => {
  const groupIndex = fields.findIndex(field => field.parent === parent);
  let index = groupIndex;

  if (groupIndex === -1) {
    const targetGroupIndex = metrics.findIndex(group => group.id === parent);

    for (let i = targetGroupIndex; i >= 0; i--) {
      const sibling = findLastIndex(fields, field => field.parent === metrics[i].id);
      if (sibling !== -1) {
        index = sibling + 1;
        break;
      }
    }
  } else {
    const parentGroup = metrics.find(group => group.id === parent);
    const targetFieldIndex = parentGroup.items.findIndex(field => field.path === path);
    let sibling = -1;

    for (let i = targetFieldIndex; i >= 0; i--) {
      sibling = fields.findIndex(field => field.path === parentGroup.items[i].path);
      if (sibling !== -1) {
        index = sibling + 1;
        break;
      }
    }

    if (sibling === -1) {
      const offset = fields.filter(f => f.parent === parent && !f.path.includes('.')).length;
      index = index + offset;
    }
  }

  return index;
};
