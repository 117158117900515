import React, { Component, FormEvent, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import {
  Button,
  Input,
  Password,
  Checkbox,
  Row,
  Col,
  Logo,
  Pagination,
  Notification,
} from 'pds';
import { formatMessage } from '~/intlProvider';
import { AuthStore } from '~/stores/authStore';
import { validateEmail, isPasswordValid } from '~/utils';
import messages from './messages';
import './styles.css';

const features = [
  {
    img: './../assets/images/signin/signin.svg',
    title: 'Plarin ID',
    text: <>Используйте преимущества персональной учетной записи.<br/>
    Cохраняйте и изменяйте личные настройки интерфейса и оповещений.<br/>
    Получайте специальные условия на использование инструментов Plarin.</>,
  },
  {
    img: './../assets/images/signin/signin.svg',
    title: 'Организация',
    text: <>Формируйте командную работу в группах, отделах и подразделениях.<br/>
    Приглашайте в рекламный кабинет Plarin любое количество коллег.<br/>
    Управляйте правами и доступом для каждого участника команды.</>,
  },
  {
    img: './../assets/images/signin/signin.svg',
    title: 'Управление бюджетами',
    text: <>Инструмент доступен в закрытом тестировании по приглашению.<br/>
    Управляйте движением средств на счетах различных<br/>
    рекламных систем через единый счет в Plarin.</>,
  },
];

interface IProps {
  authStore?: AuthStore;
}

interface IState {
  email: string;
  password: string;
  remember: boolean;
  errors: {
    email: boolean;
    password?: boolean;
  };
  step: number;
  forgot: boolean;
}

@inject('authStore')
@observer
export default class SignIn extends Component <IProps, IState> {
  errorsList: Notification;

  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: true,
      errors: {
        email: false,
        password: false,
      },
      step: 0,
      forgot: false,
    };
  }

  componentDidMount() {
    if (this.props.authStore.passwordTokenExpired) {
      this.props.authStore.addError({
        type: 'warning',
        message: formatMessage({ id: 'app.forgot_password.token_expired' }),
      });
    }
    // TODO: костыль что бы убрать минимальную ширину на телефонах
    document.body.classList.add('min-width-disable');
  }

  // TODO: костыль что бы убрать минимальную ширину на телефонах
  componentWillUnmount() {
    document.body.classList.remove('min-width-disable');
  }

  private handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { errors, email, password, remember } = this.state;

    if (!email.length || errors.email || !password.length || errors.password) {
      return;
    }
    this.props.authStore.signIn({ email, password, remember }).catch(() => {});
  }

  private handleForgotSubmit = () => {
    const { errors, email } = this.state;
    if (!email.length || errors.email) {
      return;
    }

    this.props.authStore.forgotPassword(email)
      .then(() => {
        this.setState({ forgot: false });
        this.resetFields();
      })
      .catch(() => {});
  }

  private handleEmailChange = (value: string | number) => {
    const email = value.toString();
    const emailError = validateEmail(email, true) ? false : this.state.errors.email;
    this.setState({
      email: email.toLowerCase(),
      errors: {
        ...this.state.errors,
        email: emailError,
      },
    });
  }

  private handlePasswordChange = (password: string): void => {
    const isValid = isPasswordValid(password);
    this.setState(prevState => ({
      errors: {
        email: prevState.errors.email,
        password: !isValid,
      },
      password,
    }));
  }

  private handleEmailBlur = (): void => {
    this.setState(prevState => ({
      errors: {
        password: prevState.errors.password,
        email: !validateEmail(prevState.email, true),
      },
    }));
  }

  private handlePasswordBlur = (): void => {
    const isValid = isPasswordValid(this.state.password);
    this.setState(prevState => ({
      errors: {
        email: prevState.errors.email,
        password: !isValid,
      },
    }));
  }

  private setStep = (step: number): void => {
    this.setState({ step });
  }

  private setPrevStep = (): void => {
    this.setState(prevState => ({
      step: !prevState.step
        ? features.length - 1
        : prevState.step - 1,
    }));
  }

  private setNextStep = (): void => {
    this.setState(({step}) => ({ step: step === features.length - 1 ? 0 : step + 1 }));
  }

  private toggleForgot = (): void => {
    this.setState({ forgot: !this.state.forgot });
    this.resetFields();
    this.props.authStore.clearErrors();
  }

  private toggleRemember = (): void => {
    this.setState(prevState => ({ remember: !prevState.remember }));
  }

  private resetFields = (): void => {
    this.setState({
      email: '',
      errors: { email: false, password: false },
      password: '',
      remember: true,
    });
  }

  private renderForgotForm(): React.ReactNode {
    const { errors, email } = this.state;
    return (
      <Fragment>
        <h4>{formatMessage(messages.titleForgot)}</h4>
        <Row margin="l">
          <Col span={12}>
            <p>{formatMessage(messages.instructionsForgot)}</p>
          </Col>
        </Row>
        <div className="modal-form">
          <Row margin="xl">
            <Col span={12}>
              <Input
                error={errors.email}
                errorMessage={formatMessage(messages.emailRequired)}
                label={formatMessage(messages.email)}
                onBlur={this.handleEmailBlur}
                onChange={this.handleEmailChange}
                value={email}
              />
            </Col>
          </Row>
          <Button
            className="modal-button"
            disabled={!email.length || errors.email || this.props.authStore.loading}
            loading={this.props.authStore.loading}
            onClick={this.handleForgotSubmit}
            stretch
            type="primary"
          >
            {formatMessage(messages.recover)}
          </Button>
          <Row>
            <Col span={12}>
              <Button
                className="modal-back"
                icon="arrow-small-left"
                onClick={this.toggleForgot}
                type="link"
              >
               {formatMessage(messages.back)}
              </Button>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }

  private renderSigninForm(): React.ReactNode {
    const { errors, remember, email, password } = this.state;
    const submitDisabled = (
      !email.length
      || !password.length
      || errors.email
      || errors.password
      || this.props.authStore.loading
    );

    return (
      <Fragment>
        <h4>{formatMessage(messages.title)}</h4>
        <form onSubmit={this.handleSubmit} className="modal-form">
          <Row margin="xl">
            <Col span={12}>
              <Input
                error={errors.email}
                errorMessage={formatMessage(messages.emailRequired)}
                label={formatMessage(messages.email)}
                name="email"
                onChange={this.handleEmailChange}
                onBlur={this.handleEmailBlur}
                type="email"
                value={email}
              />
            </Col>
          </Row>
          <Row margin="xl">
            <Col span={12}>
              <Password
                error={password.length > 7 ? errors.password : false}
                errorMessage={formatMessage(messages.passwordIncorrectCharsError)}
                hide
                value={password}
                label={formatMessage(messages.password)}
                name="password"
                onBlur={this.handlePasswordBlur}
                onChange={this.handlePasswordChange}
              />
            </Col>
          </Row>
          <Row justify="space-between" margin="l">
            <Col span={6}>
              <Checkbox
                checked={remember}
                label={formatMessage(messages.remember)}
                onChange={this.toggleRemember}
              />
            </Col>
            <Col span={6} className="signin-forgot">
              <Button type="link" onClick={this.toggleForgot}>
                {formatMessage(messages.passwordForgot)}
              </Button>
            </Col>
          </Row>
          <Button
            disabled={submitDisabled}
            className="modal-button"
            type="primary"
            htmlType="submit"
            stretch
            loading={this.props.authStore.loading}
          >
            {formatMessage(messages.submit)}
          </Button>
        </form>
      </Fragment>
    );
  }

  public render() {
    const { forgot, step } = this.state;
    return (
      <Row className="signin">
        <Col className="signin-modal" span={6}>
          <div className="signin-modal-group">
            <div className="signin-form">
              <Logo className="logo" />
              <Notification
                maxCount={1}
                className="notification-form"
                inline
                ref={this.props.authStore.setErrorsRef}
              />
              {forgot
                ? this.renderForgotForm()
                : this.renderSigninForm()
              }
            </div>

            <div className="signin-background-spot spot_1">
              <img src="./../assets/images/signin/spot_1.svg" />
            </div>

            <div className="signin-background-spot spot_2">
              <img src="./../assets/images/signin/spot_2.svg" />
            </div>

          </div>
        </Col>
        <Col span={6} className="signin-features">
          {features.map(({img, title, text}, index) => (
            <div
              className={cx('signin-features-frame', { _active: index === step })}
              key={title}
            >
              <div className="signin-features-frame-img">
                <img src={img} />
              </div>
              <Row>
                <Col>
                  <h2 className="signin-features-title">{title}</h2>
                </Col>
              </Row>
              <Row margin="xl">
                <p className="signin-features-text">{text}</p>
              </Row>
            </div>
          ))}
          <Row>
            <Col>
              <Button
                className="signin-features-arrow arrow-prev"
                type="plain"
                icon="arrow-small-left"
                size="large"
                onClick={this.setPrevStep}
              />
              <Button
                className="signin-features-arrow arrow-next"
                type="plain"
                icon="arrow-small-right"
                size="large"
                onClick={this.setNextStep}
              />
              <Pagination
                current={step}
                onChange={this.setStep}
                total={features.length}
                theme="white"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
