import dayjs from 'dayjs';
import {MINIMAL_ALLOWED_DAY} from '~/constants';
import {Preset} from '~/utils/presets';

export const datePresets: Preset[] = [
  {
    id: 'all_time',
    startDate: dayjs(MINIMAL_ALLOWED_DAY)
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'today',
    startDate: dayjs()
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'yesterday',
    startDate: dayjs()
      .subtract(1, 'day')
      .startOf('day'),
    endDate: dayjs()
      .subtract(1, 'day')
      .startOf('day'),
  },
  {
    id: 'last_7',
    startDate: dayjs()
      .subtract(6, 'day')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'current_month',
    startDate: dayjs()
      .startOf('month')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'last_month',
    startDate: dayjs()
      .subtract(1, 'month')
      .startOf('month')
      .startOf('day'),
    endDate: dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .startOf('day'),
  },
  {
    id: 'custom',
    startDate: dayjs()
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
];
