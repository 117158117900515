import client from './httpClient';

export const signIn = ({ email, password, remember }: {
  email: string;
  password: string;
  remember: boolean;
}) => {
  return new Promise<{expires: string, refresh_expires: string}>((resolve, reject) => client.post('/login', {
    email,
    password,
    remember_me: remember,
  })
    .then((res) => {
      const {success, expires, refresh_expires} = res.data;
      if (success) {
        resolve({expires, refresh_expires});
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error)));
};

export const refreshAuth = () => {
  return new Promise<{expires: string, refresh_expires: string}>((resolve, reject) => client.post('/refresh')
    .then(res => resolve(res.data))
    .catch(error => reject(error)));
};

export const checkAuth = () => {
  return new Promise<boolean>((resolve, reject) => client.post('/user/is-logged')
    .then(res => resolve(res.data.result.is_logged))
    .catch(error => reject(error)));
};
export const forgotPassword = (email: string) => {
  return new Promise((resolve, reject) => client.post('/user/password/forgot', { email })
    .then(() => resolve())
    .catch(error => reject(error)));
};

export const activatePasswordToken = (forgot_password_token: string) => {
  return new Promise((resolve, reject) => client.post('/user/password/activate', { forgot_password_token })
    .then(() => resolve())
    .catch(error => reject(error)));
};

export const resetPassword = ({ token, newPassword, repeatedNewPassword }: {
  token: string;
  newPassword: string;
  repeatedNewPassword: string;
}) => {
  return new Promise<string>((resolve, reject) => client.post('/user/password/new', {
    forgot_password_token: token,
    new_password: newPassword,
    new_password2: repeatedNewPassword,
  })
    .then((res) => {
      if (res.data.success) {
        resolve(res.data.email);
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error)));
};

export const signOut = () => {
  return new Promise((resolve, reject) => client.post('/logout')
    .then(res => resolve(res))
    .catch(error => reject(error))
  );
};
