import dayjs from 'dayjs';

import { MINIMAL_ALLOWED_DAY } from '~/constants';

export interface Preset {
  id: RelativePeriod;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export const defaultDatePresets: Preset[] = [
  {
    id: 'all_time',
    startDate: dayjs(MINIMAL_ALLOWED_DAY)
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'today',
    startDate: dayjs()
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'yesterday',
    startDate: dayjs()
      .subtract(1, 'day')
      .startOf('day'),
    endDate: dayjs()
      .subtract(1, 'day')
      .startOf('day'),
  },
  {
    id: 'last_2',
    startDate: dayjs()
      .subtract(1, 'day')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'last_3',
    startDate: dayjs()
      .subtract(2, 'day')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'last_7',
    startDate: dayjs()
      .subtract(6, 'day')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'last_14',
    startDate: dayjs()
      .subtract(13, 'day')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'last_28',
    startDate: dayjs()
      .subtract(27, 'day')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'current_month',
    startDate: dayjs()
      .startOf('month')
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
  {
    id: 'last_month',
    startDate: dayjs()
      .subtract(1, 'month')
      .startOf('month')
      .startOf('day'),
    endDate: dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .startOf('day'),
  },
  {
    id: 'custom',
    startDate: dayjs()
      .startOf('day'),
    endDate: dayjs()
      .startOf('day'),
  },
];

function getPresets(presets: Preset[], id?: string): Preset | Preset[] {
  if (id && typeof(id) === 'string') {
    return presets.find(preset => preset.id === id);
  }

  return presets;
}

export default getPresets;
