import { action } from 'mobx';

import { isParentDomNode } from '~/utils';

interface Popover {
  getElement: () => HTMLElement;
  hide: (fireCancel: boolean) => void;
}

export class MenusStore {
  popover: Popover;
  anyOpen: boolean;

  constructor() {
    window.addEventListener('click', this.handleClick, false);
  }

  @action.bound
  private handleClick(event: Event & { target: HTMLElement }) {
    if (!this.popover || !this.anyOpen) return;

    const parent: HTMLElement = this.popover.getElement();
    const children: HTMLElement = event.target;

    if (!children.parentNode) {
      return;
    }

    if (!isParentDomNode(parent, children)) {
      this.unregister();
    }
  }

  @action.bound
  register(popover: Popover) {
    if (this.popover && this.popover !== popover) {
      this.unregister();
    }

    this.popover = popover;
    this.anyOpen = true;
  }

  @action.bound
  unregister(fireCancel: boolean = false) {
    if (this.popover) {
      this.popover.hide(fireCancel);
    }

    this.popover = undefined;
    this.anyOpen = false;
  }
}
