import { GridApi } from 'ag-grid-community';

export class GridStore {
  private api: GridApi | undefined;

  set gridApi(api: GridApi) {
    this.api = api;
  }

  get gridApi() {
    return this.api;
  }
}
