import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Loadable from 'react-loadable';
// import { hot } from 'react-hot-loader';

import { Layout, Notification, Spinner } from 'pds';

import { isParentDomNode } from '~/utils';

import { AppStore } from '~/stores/appStore';
import { AuthStore } from '~/stores/authStore';
import { UserStore } from '~/stores/userStore';
import { MenusStore } from '~/stores/menusStore';
import { NotificationsStore } from '~/stores/notificationsStore';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';

const Core = Loadable({
  // tslint:disable-next-line
  loader: () => import(/* webpackChunkName: "Core" */ '~/pages/Core'),
  loading: () => <Spinner />,
});

interface Props {
  appStore?: AppStore;
  authStore?: AuthStore;
  userStore?: UserStore;
  menusStore?: MenusStore;
  notificationsStore?: NotificationsStore;
}

@inject('appStore', 'authStore', 'userStore', 'menusStore', 'notificationsStore')
@observer
class App extends Component<Props> {
  async componentDidMount() {
    await this.props.authStore.checkAuth();

    // if (navigator.userAgent.includes('Macintosh')) {
    //   window.addEventListener('mousewheel', this.handlePreventScrollBack, { passive: false, capture: false });
    // }
    window.addEventListener('scroll', this.handleScroll, { passive: true, capture: true });
  }

  handlePreventScrollBack = (event: WheelEvent & { target: HTMLElement }) => {
    const viewport: HTMLElement = document.querySelector('.ag-body-viewport');

    const hasScroll = event.target.scrollWidth !== event.target.clientWidth;

    const isGrid = viewport && isParentDomNode(viewport, event.target);

    if ((isGrid || !hasScroll) && event.deltaX < 0) {
      event.preventDefault();
    }
  }

  handleScroll = (event: WheelEvent & { target: HTMLElement }) => {
    if (!this.props.menusStore.anyOpen) {
      return;
    }

    if (isParentDomNode(this.props.menusStore.popover.getElement(), event.target)) {
      return;
    }

    let isParentHasScroll = false;
    let node = event.target;

    while (node !== null) {
      isParentHasScroll = node.scrollWidth !== node.clientWidth || node.scrollHeight !== node.clientHeight;
      if (isParentHasScroll) {
        break;
      }

      node = node ? node.parentElement : null;
    }

    if (!isParentHasScroll) {
      return;
    }

    this.props.menusStore.unregister(true);
  }

  render() {
    const { authStore, notificationsStore } = this.props;

    if (!authStore.isChecked) {
      return null;
    }

    if (!authStore.isAuthenticated) {
      return (
        <Layout>
          <Layout.Content>
            <Switch>
              <Route exact path="/login" component={SignIn} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Redirect to="/login" />
            </Switch>
          </Layout.Content>
          <Notification top="8px" ref={notificationsStore.setRef} />
        </Layout>
      );
    }

    return <Route component={Core} />;
  }
}

// export default hot(module)(App);
export default App;
