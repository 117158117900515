import { observable, action, computed, runInAction, toJS } from 'mobx';

import observedAction from '~/utils/observedAction';
import { captureException } from '~/utils';
import { createShape, updateShape } from '~/api/shapes';

import { appStore, adsStore, campaignsStore, shapesStore } from './';

export default class Shape {
  @observable name: string;
  @observable id: string;
  @observable unsaved: boolean;
  @observable wizard: boolean;
  @observable index: number;

  @observable.shallow campaignIds: IShape['body']['campaignIds'];
  @observable dates: IShape['body']['dates'];
  @observable mode: IShape['body']['mode'];
  @observable ads: IShape['body']['ads'];
  @observable campaigns: IShape['body']['campaigns'];
  @observable hidden: IShape['body']['hidden'];

  @observable.shallow selectedRows: any[];

  constructor(shape: IShape) {
    this.name = shape.name;
    this.id = shape.id;
    this.unsaved = shape.unsaved;
    this.wizard = shape.wizard;
    this.index = shape.index;

    this.campaignIds = shape.body.campaignIds;
    this.dates = shape.body.dates;
    this.mode = shape.body.mode;
    this.ads = shape.body.ads;
    this.campaigns = shape.body.campaigns;
    this.hidden = !!shape.body.hidden;
  }

  @computed get shape() {
    return {
      name: toJS(this.name),
      id: toJS(this.id),
      body: {
        campaignIds: toJS(this.campaignIds),
        dates: toJS(this.dates),
        mode: toJS(this.mode),
        ads: toJS(this.ads),
        campaigns: toJS(this.campaigns),
        hidden: toJS(this.hidden),
      },
      unsaved: toJS(this.unsaved),
      wizard: toJS(this.wizard),
      index: toJS(this.index),
    };
  }

  @action.bound
  @observedAction
  setName(name: string) {
    this.name = name;
  }

  @action.bound
  @observedAction
  setId(id: string) {
    this.id = id;
  }

  @action.bound
  @observedAction
  setUnsaved(state: boolean) {
    this.unsaved = state;
  }

  @action.bound
  @observedAction
  setWizard(state: boolean) {
    this.wizard = state;
  }

  @action.bound
  @observedAction
  setIndex(index: number) {
    this.index = index;
  }

  @action.bound
  @observedAction
  setSelectedRows(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  @computed get status() {
    if (this.mode === 'campaigns') {
      return this.campaigns.status;
    } else {
      return this.ads.status;
    }
  }

  @action.bound
  @observedAction
  setStatus(statuses: Status[]) {
    if (this.mode === 'campaigns') {
      this.campaigns.status = statuses;
    } else {
      this.ads.status = statuses;
    }
  }

  @action.bound
  @observedAction
  setHidden(state: boolean) {
    this.hidden = state;
  }

  @action.bound
  @observedAction
  loadData(force?: boolean) {
    if (this.mode === 'campaigns') {
      campaignsStore.loadCampaigns(this.shape, force);
    }

    if (this.mode === 'ads') {
      adsStore.loadAds(this.shape, force);
    }
  }

  @action.bound
  @observedAction
  update(reload: boolean = true) {
    if (!this.wizard) {
      this.save();
    }
    if (!this.wizard && reload) {
      this.loadData();
    }
  }

  @action.bound
  @observedAction
  async save() {
    if (this.unsaved) {
      return this.create();
    }

    try {
      await updateShape(appStore.network, this.shape);
      runInAction('shape save success', () => {
        this.unsaved = false;
        this.wizard = false;
      });
      return this.shape.id;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async create() {
    try {
      const prevId = this.shape.id;
      const id = await createShape(appStore.network, this.shape);
      runInAction('shape create success', () => {
        this.unsaved = false;
        this.wizard = false;
        this.id = id;

        shapesStore.afterShapeSave(prevId, id);

        this.loadData();
      });
      return id;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  toggleAdsStatus(derivedStatus: Status) {
    const indexOfStatus = this.ads.status.indexOf(derivedStatus);

    if (indexOfStatus !== -1) {
      this.ads.status.splice(indexOfStatus, 1);
    } else {
      this.ads.status.push(derivedStatus);
    }
  }

  @action.bound
  @observedAction
  setAdsStatuses(derivedStatuses: Status[]) {
    derivedStatuses.forEach((derivedStatus) => {
      const indexOfStatus = this.ads.status.indexOf(derivedStatus);

      if (indexOfStatus === -1) {
        this.ads.status.push(derivedStatus);
      }
    });
  }

  @action.bound
  @observedAction
  resizeColumn(path: string, width: number) {
    this[this.mode].fields.forEach((field: ShapeField) => {
      if (field.path === path) {
        field.width = width;
      }
    });

    this.update(false);
  }

  @action.bound
  @observedAction
  pinColumn(path: string, pinned: string|null) {
    const fields = this[this.mode].fields;
    const fieldIndex = fields.findIndex((field: ShapeField) => field.path === path);

    fields[fieldIndex].pinned = pinned;

    this.update(false);
  }

  @action.bound
  @observedAction
  moveColumn(columns: { index: number, path: string }[]) {
    const fields = this[this.mode].fields;

    const shapeColumns = fields.map(field => ({ index: field.index, path: field.path }));

    if (JSON.stringify(columns) === JSON.stringify(shapeColumns)) {
      return;
    }

    fields.forEach((field: ShapeField) => {
      const idx = columns.findIndex(column => field.path === column.path);
      field.index = idx;
    });

    this[this.mode].fields = fields.slice().sort((a, b) => a.index - b.index);

    this.update(false);
  }

  @action.bound
  @observedAction
  changeAggregation(path: string, aggregation: 'avg' | 'sum') {
    const fields = this[this.mode].fields;
    const fieldIndex = fields.findIndex((field: ShapeField) => field.path === path);

    if (fields[fieldIndex].math_allowed) {
      fields[fieldIndex].math_default = aggregation;
    }

    this.update(false);
  }

  @action.bound
  @observedAction
  changeSort(columns: { path: string; sort: 'asc' | 'desc' | undefined; }[]) {
    const fields = this[this.mode].fields;

    columns.forEach((column) => {
      const shapeField = fields.find(field => field.path === column.path);
      if (shapeField) shapeField.sort = column.sort;
    });

    this[this.mode].fields = fields;
    this.update(false);
  }

  @action.bound
  @observedAction
  changeName(name: string) {
    this.name = name.length < 100 ? name : this.name;

    this.update(false);
  }

  @action.bound
  @observedAction
  changeShapeMode(mode: ShapeMode) {
    this.mode = mode;

    this.update();
  }

  @action.bound
  @observedAction
  changeDatePreset(title: RelativePeriod, startDate: ISODate, endDate: ISODate, reload: boolean = true) {
    this.dates = {
      title,
      startDate,
      endDate,
    };

    this.update(reload);
  }

  @action.bound
  @observedAction
  changeSelectedCampaigns(campaignIds: number[] = []) {
    this.campaignIds = campaignIds;

    this.update();
  }

  @action.bound
  @observedAction
  changeSelectedFields(fields: ShapeField[], reload: boolean = true) {
    this[this.mode].fields = fields;

    const grouping = this[this.mode].grouping;
    const groupingFieldRemoved = fields.findIndex(field => field.path === grouping) === -1;

    if (groupingFieldRemoved) {
      this[this.mode].grouping = '';
    }

    this.update(reload);
  }

  @action.bound
  @observedAction
  changeGrouping(group: string) {
    this[this.mode].grouping = group === this[this.mode].grouping ? null : group;

    this.update(false);
  }

  @action.bound
  @observedAction
  changePivot(pivot: boolean, pivotOptions: PivotOptions) {
    this[this.mode].pivot = pivot;
    this[this.mode].pivotOptions = pivotOptions;

    if (pivot) {
      this[this.mode].grouping = null;
    }

    this.update(false);
  }
}
