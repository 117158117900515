import { defineMessages } from 'react-intl';

const definedMessages = defineMessages({
  title: {
    id: 'app.forgot_password.title',
    defaultMessage: 'Задайте новый пароль',
  },
  submit: {
    id: 'app.forgot_password.submit',
    defaultMessage: 'Восстановить',
  },
  tokenExpired: {
    id: 'app.forgot_password.token_expired',
    defaultMessage: 'Срок действия ссылки для восстановления пароля истёк',
  },
  resetSuccess: {
    id: 'app.forgot_password.reset_success',
    defaultMessage: 'Пароль успешно изменен',
  },
  resetError: {
    id: 'app.forgot_password.reset_error',
    defaultMessage: 'Ошибка при изменении пароля',
  },
});

export default definedMessages;
