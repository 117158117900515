import { observable, action, computed } from 'mobx';

import observedAction from '~/utils/observedAction';
import { SIDEBAR_COLLAPSED } from '~/constants';

import {
  clientsStore,
  shapesStore,
  adsStore,
  campaignsStore,
  userStore,
  notificationsStore, paymentStore, organizationStore, authStore,
} from './';

export class AppStore {
  @observable sidebarCollapsed = false;

  /* aside's */
  @observable campaignsSettings = false;
  @observable adsSettings = false;
  @observable pivotSettings = false;

  /* modals */
  @observable tabDeleteModal = false;

  @observable network: Network = 'mt';

  constructor() {
    this.sidebarCollapsed = localStorage.getItem(SIDEBAR_COLLAPSED) === '1';
  }

  private isAnyModalOpen() {
    return this.tabDeleteModal
      || this.campaignsSettings
      || this.adsSettings
      || this.pivotSettings;
  }

  @computed get bodyBlur(): boolean {
    return this.isAnyModalOpen();
  }

  @action.bound
  @observedAction
  setNetwork(value: Network) {
    this.network = value;
  }

  @action.bound
  @observedAction
  setTabDeleteModal(state: boolean) {
    this.tabDeleteModal = state;
  }

  @action.bound
  @observedAction
  setCampaignsSettings(state: boolean) {
    this.campaignsSettings = state;
  }

  @action.bound
  @observedAction
  setAdsSettings(state: boolean) {
    this.adsSettings = state;
  }

  @action.bound
  @observedAction
  setPivotSettings(state: boolean) {
    this.pivotSettings = state;
  }

  @action.bound
  @observedAction
  setSidebarCollapsed(state: boolean) {
    this.sidebarCollapsed = state;
  }

  @action
  @observedAction
  reset() {
    authStore.reset();
    userStore.reset();
    paymentStore.reset();
    organizationStore.reset();
    clientsStore.reset();
    shapesStore.reset();
    adsStore.reset();
    campaignsStore.reset();
    notificationsStore.reset();
  }
}
