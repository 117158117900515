import {observable, action, runInAction, computed} from 'mobx';
import {GridApi} from 'ag-grid-community';
import dayjs from 'dayjs';

import observedAction from '~/utils/observedAction';
import {captureException, checkQuery, sortByDescription, toNumber} from '~/utils';
import {
  fetchClients,
  fetchClientsInfo,
  fetchManagers,
  fetchManagersInfo,
  fetchActionsInfo,
  fetchBalances,
  fetchAccounts,
  fetchOrders,
  topup,
  refund,
  cancel,
  addManager,
  createClients,
  editClients,
  fetchSuppliers, editManager, disconnectManager, removeManagerClient
} from '~/api/payment';
import {formatMessage} from '~/intlProvider';

import {NoticeProps} from 'pds';

import {appStore, notificationsStore} from './';
import {datePresets} from '~/pages/Core/payment/MyTarget/toolbars/datePresets';
import {statusTypes} from '~/pages/Core/payment/MyTarget/status-types';

type ClientsFilters = {
  query?: string;
  ids?: string[];
};

export class PaymentStore {
  gridApi: GridApi = null;

  @observable dates: IShape['body']['dates'] = {
    title: datePresets[0].id,
    startDate: <any>datePresets[0].startDate,
    endDate: <any>datePresets[0].endDate,
  };

  @observable.shallow accounts: PaymentAccounts = {};
  @observable.shallow balances: PaymentBalances = {};
  @observable.shallow orders: { [id: string]: PaymentOrder[] } = {};
  @observable.shallow ordersTotal: { [id: string]: number } = {};
  ordersLimit = 10;
  is_alternative_payments: boolean;

  @observable.shallow clients: { [id: string]: PaymentClient[Network] } = {};
  @observable.shallow terms: { web?: number, mob?: number } = {};
  @observable.shallow clientsFilters: ClientsFilters = {};
  @observable.shallow selectedClientsIds: string[] = [];
  @observable.shallow managers: { [id: string]: PaymentManager[Network] } = {};
  @observable.shallow suppliers: PaymentSupplier[] = [];
  @observable.shallow errors: NoticeProps[] = [];
  @observable.shallow selectedStatus: string[] = Object.keys(statusTypes);

  @observable loading: boolean = true;

  get exportFileName(): string {
    const defaultName = 'myTarget accounts';
    const defaultExt = 'xlsx';
    if (this.dates['title'] === 'all_time') {
      return `${defaultName} - All time.${defaultExt}`;
    }
    else {
      const startDate = dayjs(this.dates['startDate']).format('DD.MM.YYYY');
      const endDate = dayjs(this.dates['endDate']).format('DD.MM.YYYY');
      return `${defaultName} - ${startDate} - ${endDate}.${defaultExt}`;
    }
  }

  currencyFormatter = {style: 'currency', currency: 'RUB'};

  orderLabels = {
    type: {
      topup: 'Пополнение баланса клиента',
      refund: 'Возврат средств с баланса клиента',
      replenish: 'Пополнение лицевого счета',
      withdraw: 'Снятие средств с лицевого счета',
    },
    status: {
      open: {type: 'moderation', label: 'Открыта'},
      canceled: {type: 'error', label: 'Отменена'},
      done: {type: 'success', label: 'Завершена'},
      aborted: {type: 'error', label: 'Не выполнена'},
    },
  };

  @computed get sortedClients() {
    return sortByDescription<PaymentMTClient>(Object.values(this.clients));
  }

  @computed get filteredClients() {
    const {query, ids} = this.clientsFilters;

    const filteredClients = this.sortedClients.filter((client) => {
      if (ids && !ids.includes(client._id)) {
        return false;
      }

      if (query && query.length && ![
        'description',
        'name',
        'managersLabel',
        'balance',
        'balance_hold',
        'spent',
        'clicks',
        'impressions',
        'status',
      ].some((field: keyof PaymentClient[Network]) => checkQuery(query, client[field]))) {
        return false;
      }

      return true;
    });

    return filteredClients;
  }

  @computed get filteredClientsIds() {
    return this.filteredClients.map(client => client._id);
  }

  @computed get selectedClients() {
    return this.selectedClientsIds.map(id => this.clients[id]);
  }

  @action.bound
  setGridApi(api: GridApi) {
    this.gridApi = api;
  }

  @action.bound
  updateGrid() {
    this.gridApi.setRowData(this.filteredClients);
    this.gridApi.redrawRows({rowNodes: [this.gridApi.getPinnedBottomRow(0)]});

    this.selectedClientsIds.forEach((id) => {
      this.gridApi.getRowNode(id).setSelected(true);
    });
  }

  @action.bound
  async fetchClients(refresh?: boolean) {
    this.loading = true;
    refresh && this.gridApi && this.gridApi.showLoadingOverlay();
    try {
      const reqParams = {};
      if (this.dates['title'] === 'custom') {
        reqParams['date_from'] = this.dates['startDate'];
        reqParams['date_to'] = this.dates['endDate'];
      }
      else if (this.dates['title'] !== 'all_time') {
        reqParams['relative_period'] = this.dates['title'];
      }
      const result = await fetchClients(appStore.network, reqParams);
      await this.fetchManagers();
      runInAction('fetchClients success', () => {
        this.setClients(result.clients, result.stat);
        this.terms = result.terms || {};
        this.updateGrid();
        this.loading = false;
        refresh && this.gridApi && this.gridApi.hideOverlay();
      });
    } catch (error) {
      runInAction('fetchClients fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });
        this.clients = {};
        this.terms = {};
        this.loading = false;
        refresh && this.gridApi && this.gridApi.hideOverlay();
      });
      console.error(error);
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchClientsInfo() {
    this.loading = true;
    try {
      const ids = this.selectedClientsIds;
      const info = await fetchClientsInfo(appStore.network, ids);
      runInAction('fetchClientsInfo success', () => {
        info.managers.forEach((manager) => {
          this.managers[manager._id] = manager;
        });
        this.setClients(info.clients, {}, true);
        this.updateGrid();

        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchClientsInfo fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });

        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchSuppliers() {
    this.loading = true;
    this.suppliers = [];
    try {
      const suppliers = await fetchSuppliers(appStore.network);
      runInAction('fetchSuppliers success', () => {
        if (suppliers) {
          this.suppliers = suppliers;
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchSuppliers fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });

        this.suppliers = [];
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchManagers() {
    this.loading = true;
    try {
      const managers = await fetchManagers(appStore.network);
      runInAction('fetchManagers success', () => {
        this.managers = {};

        managers.forEach((manager) => {
          this.managers[manager._id] = manager;
        });

        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchManagers fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });

        this.managers = {};
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchManagersInfo(ids?: string[]) {
    this.loading = true;
    try {
      const info = await fetchManagersInfo(appStore.network, ids);
      runInAction('fetchManagersInfo success', () => {
        info.managers.forEach((manager) => {
          this.managers[manager._id] = manager;
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchManagersInfo fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchActionsInfo() {
    this.loading = true;
    this.clearErrors();
    try {
      const ids = this.selectedClientsIds;
      const info = await fetchActionsInfo(appStore.network, ids);
      runInAction('fetchInfo success', () => {
        const dt = dayjs().format('DD.MM.YYYY HH:mm:ss');
        this.terms = info.terms || {};
        this.accounts = info.accounts;
        this.accounts.master.dt = this.accounts.hold.dt = dt;
        this.setClients(info.clients, {}, true);
        this.updateGrid();

        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchInfo fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchAccounts() {
    this.loading = true;
    this.clearErrors();
    try {
      const accountsInfo = await fetchAccounts();
      runInAction('fetchAccounts success', () => {
        const dt = dayjs().format('DD.MM.YYYY HH:mm:ss');
        this.accounts = accountsInfo.accounts;
        this.is_alternative_payments = accountsInfo.is_alternative_payments;
        if (this.accounts.master !== undefined && this.accounts.hold !== undefined) {
          this.accounts.master.dt = this.accounts.hold.dt = dt;
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchAccounts fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchOrders(req?: PaymentOrdersRequest) {
    this.loading = true;
    this.clearErrors();
    try {
      if (req && req.client__id && !req.limit) {
        req.limit = this.ordersLimit;
      }
      const result = await fetchOrders(req);
      result.orders.forEach((order) => {
        order.clientCName = order.client ? order.client.description : '';
        order.clientName = order.client ? order.client.name : '';
        order.authorName = order.created_by.user_name;
        order.typeLabel = this.orderLabels.type[order.type];
        order.statusLabel = this.orderLabels.status[order.status].label;
        order.dtLabel = dayjs(order.dt).format('DD.MM.YYYY HH:mm:ss');

        if (!order.client) {
          order.client = {};
        }

        if (order.description === '') {
          delete order.description;
        }

        if (['refund', 'withdraw'].includes(order.type)) {
          order.amount = -order.amount;
        }
      });

      runInAction('fetchOrders success', () => {
        const id = (req && req.client__id) || 'account';
        if (!req.skip) {
          this.orders[id] = [];
        }

        this.orders[id].push(...result.orders);
        this.ordersTotal[id] = result.total_count;

        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchOrders fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async fetchBalances() {
    this.loading = true;
    this.clearErrors();
    try {
      const balances = await fetchBalances();
      runInAction('fetchBalances success', () => {
        this.balances = balances;

        this.loading = false;
      });
    } catch (error) {
      runInAction('fetchBalances fail', () => {
        this.balances = {};
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при получении данных',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async topup(req: PaymentActionRequest[]) {
    this.loading = true;
    this.clearErrors();
    try {
      const errors = await topup(appStore.network, req);
      let ids: string[] = [];

      if (errors && errors.length) {
        runInAction('topup partial success', () => {
          ids = this.handleBalanceActionErrors(errors);

          this.loading = false;
        });
      } else {
        runInAction('topup success', () => {
          setTimeout(
            () => {
              notificationsStore.add({
                type: 'success',
                message: formatMessage({id: 'app.payment.mt.topup_success'}),
              });
            },
            200
          );

          this.finishAction();
        });
      }

      return ids;
    } catch (error) {
      runInAction('topup fail', () => {
        this.addError({
          id: 500,
          type: 'error',
          message: 'Ошибка при создании заявки на пополнение MT счета',
        });

        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async refund(req: PaymentActionRequest[]) {
    this.loading = true;
    this.clearErrors();
    try {
      const errors = await refund(appStore.network, req);
      let ids: string[] = [];

      if (errors && errors.length) {
        runInAction('refund partial success', () => {
          ids = this.handleBalanceActionErrors(errors);

          this.loading = false;
        });
      } else {
        runInAction('refund success', () => {
          setTimeout(
            () => {
              notificationsStore.add({
                type: 'success',
                message: formatMessage({id: 'app.payment.mt.refund_success'}),
              });
            },
            200
          );

          this.finishAction();
        });
      }

      return ids;
    } catch (error) {
      runInAction('refund fail', () => {
        this.addError({
          id: 500,
          type: 'error',
          message: 'Ошибка при создании заявки на возврат средств на лицевой счет',
        });

        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async cancel(cancelData: { order_id: string, description?: string }) {
    this.loading = true;

    try {
      await cancel(appStore.network, cancelData);

      runInAction('cancel success', () => {
        setTimeout(
          () => {
            notificationsStore.add({
              type: 'success',
              message: formatMessage({id: 'app.payment.mt.cancel_success'}),
            });
          },
          200
        );

        this.loading = false;
      });
    } catch (error) {
      runInAction('cancel fail', () => {
        if (error.response && error.response.status === 400) {
          notificationsStore.add({
            id: 400,
            type: 'warning',
            message: formatMessage({id: 'app.payment.mt.cancel_bad_request'}),
          });
        } else if (error.response && error.response.status === 404) {
          notificationsStore.add({
            id: 404,
            type: 'warning',
            message: formatMessage({id: 'app.payment.mt.order_not_found'}),
          });
        } else {
          notificationsStore.add({
            id: 500,
            type: 'error',
            message: 'Ошибка при попытке отменить заявку',
          });
        }
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  handleBalanceActionErrors(errors: PaymentActionError[]) {
    const ids: string[] = [];
    errors.forEach(({status, client__id: id, balance, is_deleted}: PaymentActionError) => {
      ids.push(id);
      if (status === 400) {
        this.clients[id].balance = balance;
      } else if (status === 404) {
        this.addError({
          id,
          type: 'error',
          message: `Клиент ${id} не найден`,
        });
      } else if (status === 409) {
        this.clients[id].is_deleted = is_deleted;
      } else {
        this.addError({
          id,
          type: 'warning',
          message: 'Неправильные параметры запроса',
        });
      }
    });

    this.addError({
      id: 'warning',
      type: 'warning',
      message: 'Не удалось создать заявку для некоторых клиентов',
    });

    return ids;
  }

  @action.bound
  @observedAction
  async addManager(req: PaymentAddManagerRequest, showSuccessNotification: boolean = true) {
    this.loading = true;
    this.clearErrors();
    try {
      const {errors, _id} = await addManager(appStore.network, req);
      const ids: string[] = [];

      if (errors && errors.length) {
        runInAction('addManager partial success', () => {
          errors.forEach(({status, client__id: id}: PaymentActionError) => {
            ids.push(id);
            const name = this.clients[id].name;
            if (status === 404) {
              delete this.clients[id];
              this.addError({
                id,
                type: 'warning',
                message: `Клиент ${name} не найден`,
              });
            } else if (status === 463) {
              this.clients[id].status = 'blocked';
              this.addError({
                id,
                type: 'warning',
                message: `Статус клиента ${name} не активен`,
              });
            } else if (status === 464) {
              delete this.clients[id];
              this.addError({
                id,
                type: 'warning',
                message: `Клиент ${name} больше не существует`,
              });
            } else if (status === 465) {
              this.addError({
                id,
                type: 'warning',
                message: `Клиент ${name} и менеджер ${req.name} находятся в разных поставщиках`,
              });
            } else if (status === 466) {
              this.addError({
                id,
                type: 'warning',
                message: `Обибка при добавлении клиента ${name} в ведение менеджера ${req.name}`,
              });
            } else {
              this.handleAddManagerError(status);
            }
          });

          this.loading = false;
        });
      } else {
        runInAction('addManager success', () => {
          if (showSuccessNotification) {
            setTimeout(
              () => {
                notificationsStore.add({
                  type: 'success',
                  message: 'Менеджер успешно добавлен',
                });
              },
              200
            );
          }

          this.finishAction();
        });
      }

      return {ids, _id};
    } catch (error) {
      runInAction('addManager fail', () => {
        this.handleAddManagerError(error.response && error.response.status);
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async editManager(req: PaymentEditManagerRequest, showSuccessNotification: boolean = true) {
    this.loading = true;
    this.clearErrors();
    try {
      const {name, ...reqData} = req;
      const {errors, _id} = await editManager(appStore.network, reqData);
      const ids: string[] = [];

      if (errors && errors.length) {
        runInAction('editManager partial success', () => {
          errors.forEach(({status, client__id: id}: PaymentActionError) => {
            if (id) {
              ids.push(id);
              const name = this.clients[id].name;
              if (status === 404) {
                delete this.clients[id];
                this.addError({
                  id,
                  type: 'warning',
                  message: `Клиент ${name} не найден`,
                });
              } else if (status === 463) {
                this.clients[id].status = 'blocked';
                this.addError({
                  id,
                  type: 'warning',
                  message: `Статус клиента ${name} не активен`,
                });
              } else if (status === 464) {
                delete this.clients[id];
                this.addError({
                  id,
                  type: 'warning',
                  message: `Клиент ${name} больше не существует`,
                });
              } else if (status === 465) {
                this.addError({
                  id,
                  type: 'warning',
                  message: `Клиент ${name} и менеджер ${req.name} находятся в разных поставщиках`,
                });
              } else if (status === 466) {
                this.addError({
                  id,
                  type: 'warning',
                  message: `Обибка при добавлении клиента ${name} в ведение менеджера ${req.name}`,
                });
              }
            } else {
              this.handleEditManagerError(status);
            }
          });

          this.loading = false;
        });
      } else {
        runInAction('editManager success', () => {
          if (showSuccessNotification) {
            setTimeout(
              () => {
                notificationsStore.add({
                  type: 'success',
                  message: 'Менеджер успешно сохранен',
                });
              },
              200
            );
          }

          this.finishAction();
        });
      }

      return {ids, _id};
    } catch (error) {
      runInAction('editManager fail', () => {
        this.handleAddManagerError(error.response && error.response.status);
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async removeManagerClient(req: PaymentRemoveManagerClientRequest) {
    this.loading = true;
    this.clearErrors();
    try {
      await removeManagerClient(appStore.network, req);
      runInAction('removeManagerClient success', () => {
        notificationsStore.add({
          type: 'success',
          message: 'Клиент успешно отвязан',
        });
        const {manager__id, client__id} = req;
        const client = this.clients[client__id];
        const foundManagerIndex = client.managers.findIndex(m => m._id === manager__id);
        if (foundManagerIndex > -1) {
          client.managers.splice(foundManagerIndex, 1);
        }
        if (client.managers) {
          client.managersLabel = client.managers.map(({_id}) =>
            this.managers[_id] && this.managers[_id].name).join(', ');
        }
        this.loading = false;
        return null;
      });
    } catch (error) {
      runInAction('removeManagerClient fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при попытке отвязать клиента',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async disconnectManager(req: PaymentDisconnectManagerRequest) {
    this.loading = true;
    this.clearErrors();
    try {
      await disconnectManager(appStore.network, req);
      runInAction('disconnectManager success', () => {
        notificationsStore.add({
          type: 'success',
          message: 'Менеджер успешно отвязан',
        });
        this.loading = false;
        this.finishAction();
        return null;
      });
    } catch (error) {
      runInAction('disconnectManager fail', () => {
        notificationsStore.add({
          type: 'error',
          message: 'Ошибка при попытке отвязать менеджера',
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  handleAddManagerError(status?: number) {
    if (status === 400) {
      this.addError({
        id: 400,
        type: 'warning',
        message: 'Менеджер уже подключен к существующему кабинету',
      });
    } else if (status === 461) {
      this.addError({
        id: 461,
        type: 'warning',
        message: 'Ошибка при добавлении менеджера к поставщику',
      });
    } else if (status === 422) {
      this.addError({
        id: 422,
        type: 'warning',
        message: 'Неправильные параметры запроса',
      });
    } else if (status === 467) {
      this.addError({
        id: 467,
        type: 'warning',
        message: 'Менеджер уже подключен к текущему кабинету',
      });
    } else {
      this.addError({
        id: '500',
        type: 'error',
        message: 'Ошибка при сохранении менеджера',
      });
    }
  }

  @action.bound
  @observedAction
  handleEditManagerError(status?: number) {
    if (status === 400) {
      this.addError({
        id: 400,
        type: 'warning',
        message: 'Менеджер уже подключен к существующему кабинету',
      });
    } else if (status === 461) {
      this.addError({
        id: 461,
        type: 'warning',
        message: 'Ошибка редактирования менеджера',
      });
    } else if (status === 464) {
      this.addError({
        id: 464,
        type: 'warning',
        message: 'Ошибка удаления клиента из ведения менеджера',
      });
    } else if (status === 467) {
      this.addError({
        id: 467,
        type: 'warning',
        message: 'У данного поставщика запрещено добавлять клиентов',
      });
    } else if (status === 462) {
      this.addError({
        id: 462,
        type: 'warning',
        message: 'Клиент не найден',
      });
    } else if (status === 465) {
      this.addError({
        id: 465,
        type: 'warning',
        message: 'Ошибка изменения прав доступа клиента',
      });
    } else {
      this.addError({
        id: '500',
        type: 'error',
        message: 'Внутренняя ошибка сервиса',
      });
    }
  }

  @action.bound
  @observedAction
  async createClients(req: PaymentCreateClientsRequest) {
    this.loading = true;
    this.clearErrors();
    try {
      const errors = await createClients(appStore.network, req);
      const names: string[] = [];

      if (errors && errors.length) {
        runInAction('createClients partial success', () => {
          errors.forEach(({status, description}: PaymentActionError) => {
            names.push(description);
            if (status === 463) {
              this.addError({
                id: description,
                type: 'warning',
                message: `Ошибка при создании клиента ${description}`,
              });
            } else if (status === 464) {
              this.addError({
                id: description,
                type: 'warning',
                message: `Ошибка при подключении клиента ${description} к менеджерам`,
              });
            } else {
              this.handleCreateClientsError(status);
            }
          });

          this.loading = false;
        });
      } else {
        runInAction('createClients success', () => {
          setTimeout(
            () => {
              notificationsStore.add({
                type: 'success',
                message: 'Клиенты успешно созданы',
              });
            },
            200
          );

          this.finishAction();
        });
      }

      return names;
    } catch (error) {
      runInAction('createClients fail', () => {
        this.handleCreateClientsError(error.response && error.response.status);
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  handleCreateClientsError(status: number) {
    if (status === 461) {
      this.fetchManagers();
      this.addError({
        id: 461,
        type: 'warning',
        message: 'Не все указанные менеджеры подключены к кабинету',
      });
    } else if (status === 462) {
      this.addError({
        id: 462,
        type: 'warning',
        message: 'В запросе указаны менеджеры из разных агенств',
      });
    } else if (status === 422) {
      this.addError({
        id: 422,
        type: 'warning',
        message: 'Неправильные параметры запроса',
      });
    } else {
      this.addError({
        id: 500,
        type: 'error',
        message: 'Ошибка при создании клиентов',
      });
    }
  }

  @action.bound
  @observedAction
  async editClients(req: PaymentEditClientsRequest, refreshOnError = false) {
    this.loading = true;
    this.clearErrors();
    try {
      const errors = await editClients(appStore.network, req);
      const ids: { clients: string[] } = {clients: []};

      if (errors && errors.length) {
        runInAction('editClients partial success', () => {
          errors.forEach(({status, client__id: id}: PaymentActionError) => {
            if (status === 404) {
              const name = this.clients[id].description;
              delete this.clients[id];
              this.addError({
                id,
                type: 'warning',
                message: `Клиент ${name} не найден`,
              });
            } else if (status === 461 || status === 462) {
              if (!ids.clients.includes(id)) {
                ids.clients.push(id);
              }
              this.addError({
                id,
                type: 'warning',
                message: `Ошибка при редактировании клиента ${this.clients[id].description}`,
              });
            } else if (status === 463) {
              if (!ids.clients.includes(id)) {
                ids.clients.push(id);
              }
              this.addError({
                id,
                type: 'error',
                message: `Невозможно добавить менеджеров у данного поставщика`,
              });
            }else {
              this.handleEditClientError(status);
            }
          });

          this.loading = false;

          if (refreshOnError) {
            this.fetchClients();
          }
        });
      } else {
        runInAction('editClients success', () => {
          setTimeout(
            () => {
              notificationsStore.add({
                type: 'success',
                message: 'Клиенты успешно отредактированы',
              });
            },
            200
          );

          this.finishAction();
        });
      }

      return ids;
    } catch (error) {
      runInAction('editClients fail', () => {
        this.handleEditClientError(error.response && error.response.status);
        this.loading = false;
      });

      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  handleEditClientError(status: number) {
    if (status === 422) {
      this.addError({
        id: 422,
        type: 'warning',
        message: 'Неправильные параметры запроса',
      });
    } else {
      this.addError({
        id: 500,
        type: 'error',
        message: 'Ошибка при редактировании клиентов',
      });
    }
  }

  @action.bound
  @observedAction
  finishAction() {
    this.loading = false;
    this.clearErrors();

    this.fetchClients();
  }

  @action.bound
  @observedAction
  setClients(clients: PaymentClient[Network][], stat?: {
    [id: string]: {
      total: {
        clicks: number;
        impressions: number;
        spent: number;
      }
    }
  },         update?: boolean) {
    clients.forEach((client) => {
      const prevData = update ? this.clients[client._id] : {};
      this.clients[client._id] = {...prevData, ...client};
      this.clients[client._id].balance = toNumber(client.balance);
      this.clients[client._id].balance_hold = toNumber(client.balance_hold);
      const balance_active = toNumber(client.balance) - toNumber(client.balance_hold);
      this.clients[client._id].balance_active = balance_active > 0 ? balance_active : 0;

      if (stat && stat[client.id] && stat[client.id].total) {
        this.clients[client._id].clicks = stat[client.id].total.clicks;
        this.clients[client._id].impressions = stat[client.id].total.impressions;
        this.clients[client._id].spent = stat[client.id].total.spent;
      }
      if (client.managers) {
        this.clients[client._id].managersLabel = client.managers.map(({_id}) =>
          this.managers[_id] && this.managers[_id].name).join(', ');
      }
    });
  }

  @action.bound
  @observedAction
  addClientsFilters(filters: Partial<ClientsFilters>) {
    this.clientsFilters = {...this.clientsFilters, ...filters};
    this.updateGrid();
  }

  @action.bound
  @observedAction
  clearClientsFilters() {
    this.clientsFilters = {};
    this.updateGrid();
  }

  @action.bound
  @observedAction
  setSelectedClientsIds(ids: string[]) {
    this.selectedClientsIds = ids;
  }

  @action.bound
  @observedAction
  deselectAllClients() {
    this.selectedClientsIds = [];
    this.gridApi.deselectAll();
  }

  @action.bound
  @observedAction
  addError(error: NoticeProps) {
    if (!this.errors.find(({id}) => error.id === id)) {
      error.onClose = this.removeError;
      this.errors.push(error);
    }
  }

  @action.bound
  @observedAction
  removeError(id: string | number) {
    this.errors = this.errors.filter(error => error.id !== id);
  }

  @action.bound
  @observedAction
  clearErrors() {
    this.errors = [];
  }

  @action.bound
  @observedAction
  reset() {
    this.clients = {};
    this.clientsFilters = {};
    this.selectedClientsIds = [];
    this.errors = [];
    this.balances = {};
    this.loading = true;
  }

  @action.bound
  @observedAction
  changeDatePreset(title: RelativePeriod, startDate: ISODate, endDate: ISODate, reload: boolean = true) {
    this.dates = {
      title,
      startDate,
      endDate,
    };
    this.fetchClients(reload);
  }

  exportDataAsExcel = () => {
    this.gridApi.exportDataAsExcel({
      columnKeys: ['description', 'name', 'managers', 'balance', 'balance_hold', 'spent', 'impressions', 'clicks', 'status'],
      fileName: this.exportFileName,
      processCellCallback: ({column, node, value}) => {
        const {colDef: {cellRendererParams, cellRenderer}} = column;
        if (cellRenderer === 'doubleCell') {
          if (column.getColId() === 'description') {
            return node.data[cellRendererParams.fields[0]];
          }
          return cellRendererParams.fields.map(field => node.data[field]).join(' ');
        }
        return value;
      },
    });
  }

  @action.bound
  setSelectedStatus(selectedStatus: string[]) {
    this.selectedStatus = selectedStatus;
  }
}
