import { AxiosResponse } from 'axios';
import client from './httpClient';

interface fetchAds {
  status?: number[];
  ad_ids?: number[];
  campaign_ids?: number[];
  client_ids?: number[];
  fields?: string[];
  relative_period?: RelativePeriod;
  date_from?: ISODate;
  date_to?: ISODate;
  shapeId: string;
  network: Network;
}

export const fetchAds = ({
  status,
  ad_ids,
  campaign_ids,
  client_ids,
  fields,
  relative_period,
  date_from,
  date_to,
  shapeId,
  network,
}: fetchAds) => {

  let options: Partial<fetchAds> = {
    client_ids,
    fields,
    status,
  };

  if (campaign_ids && campaign_ids.length) {
    options.campaign_ids = campaign_ids;
  }

  if (relative_period === 'custom') {
    options = { ...options, date_from, date_to };
  } else if (relative_period === 'all_time') {
    // options = { ...options };
  } else {
    options = { ...options, relative_period };
  }

  return client.post(`/${network}/ads`, options);
};

export const fetchAdsByShape = (network: Network, shape: IShape) => {
  const { dates, campaignIds } = shape.body;
  const { status } = shape.body[shape.body.mode];
  const fields = shape.body[shape.body.mode].fields
    .filter(field => !field.local)
    .map(field => field.path);

  return new Promise<Ad[]>((resolve, reject) => fetchAds({
    network,
    fields,
    relative_period: dates.title,
    date_from: dates.startDate,
    date_to: dates.endDate,
    campaign_ids: campaignIds,
    status,
    shapeId: shape.id,
  })
    .then(res => resolve(res.data.result.ads))
    .catch(error => reject(error))
  );
};

export const updateAdsStatus = (network: Network, ads: number[], status: Status) => {
  return new Promise<number[]>((resolve, reject) => client.post('/rpc', {
    command: 'rpc.mt.banners.set_status',
    data: { ad_ids: ads, status },
  })
    .then((res: AxiosResponse<{ ok: string[] }>) => resolve(res.data.ok.map(el => Number(el))))
    .catch(error => reject(error))
  );
};

export function updateAds(network: Network, ads: Ad[]) {
  return new Promise<number[]>((resolve, reject) => client.post('/rpc', {
    command: 'rpc.mt.banners.update',
    data: ads,
  })
    .then((res: AxiosResponse<{ ok: string[] }>) => {
      if (res.data.ok) {
        resolve(res.data.ok.map(el => Number(el)));
      } else {
        reject(res.data);
      }
    })
    .catch(error => reject(error))
  );
}

export const getAdPreview = (network: Network, id: number) => {
  return new Promise<CreativePreview>((resolve, reject) => client.post(`/${network}/ad/preview`, {
    ad_id: id,
  })
    .then((res: AxiosResponse<{ preview: CreativePreview }>) => resolve(res.data.preview))
    .catch(error => reject(error))
  );
};
