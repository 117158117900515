import client from './httpClient';

export const fetchUser = () => {
  return new Promise<User>((resolve, reject) => client.post('/user/info')
    .then(res => resolve(res.data.user))
    .catch(error => reject(error)));
};

export const checkUserExists = (plarin_id: string) => {
  return new Promise<User>((resolve, reject) => client.post('/user/exists', { plarin_id })
    .then(res => resolve(res.data.user))
    .catch(error => reject(error)));
};

export const updateUser = (user: Partial<User>) => {
  return new Promise<string>((resolve, reject) => client.post('/user/update', user)
    .then((res) => {
      if (res.data.success) {
        resolve();
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error)));
};

export const changePassword = ({ password, newPassword, repeatedNewPassword }: UserPasswords) => {
  return new Promise((resolve, reject) => client.post('/user/password/change', {
    password,
    new_password: newPassword,
    new_password2: repeatedNewPassword,
  })
    .then(res => resolve(res))
    .catch(error => reject(error))
  );
};
