import { addBreadcrumb, Severity } from '@sentry/browser';

function observedAction(target: Object, name: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  const method = descriptor.value;

  descriptor.value = function (...args: any[]) {
    const params = args.map(a => JSON.stringify(a, getCircularReplacer())).join();
    addBreadcrumb({
      message: `${target.constructor.name}.${name}(${params})`,
      level: Severity.Info,
    });
    return method.apply(this, args);
  };

  return descriptor;
}

function getCircularReplacer() {
  const seen = new WeakSet();

  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circullar]';
      }
      seen.add(value);
    }
    return value;
  };
}

export default observedAction;
