import axios, {AxiosRequestConfig} from 'axios';
import dayjs from 'dayjs';

import {authStore, userStore} from '~/stores';
import {API_URL, API_PROTOCOL, EXPIRE_TOKEN, REFRESH_TOKEN_PATH} from '~/constants';
import {resetApp} from '~/utils/resetApp';
import {waitFor} from '~/utils';

const refershExceptions = ['/login', '/refresh', '/logout', '/user/forgot-password'];

let refreshing = false;
let logout = false;

export async function refresh() {
  if (refreshing) {
    const condition = () => !refreshing;
    await new Promise((resolve) => {
      waitFor(condition, resolve);
    });
    return;
  }

  refreshing = true;

  try {
    const res = await axios.post(`${API_PROTOCOL}://${API_URL}/${REFRESH_TOKEN_PATH}`, null, {
      baseURL: `${API_PROTOCOL}://${API_URL}`,
      withCredentials: true,
    });
    const {expires} = res.data;
    const storage = localStorage.getItem(EXPIRE_TOKEN) ? localStorage : sessionStorage;
    storage.setItem(EXPIRE_TOKEN, dayjs(expires).format());

    refreshing = false;

    return Promise.resolve();
  } catch (err) {
    refreshing = false;

    return Promise.reject(err);
  }
}

const client = axios.create({
  baseURL: `${API_PROTOCOL}://${API_URL}`,
  withCredentials: true,
});

client.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.url && (refershExceptions.some(exception => config.url.includes(exception)))) {
      return config;
    }

    const storage = localStorage.getItem(EXPIRE_TOKEN) ? localStorage : sessionStorage;
    const expires = storage.getItem(EXPIRE_TOKEN);

    if (!expires) {
      if (!logout) {
        authStore.signOut();
        logout = true;
      }
      return config;
    }

    if (dayjs().isAfter(dayjs(expires)) || dayjs().isSame(dayjs(expires), 'minute')) {
      await refresh();
    }

    logout = false;
    return config;
  }
);

client.interceptors.response.use(
  response => response,
  async (error) => {
    if (error.response && error.response.status === 403) {
      await userStore.fetchUser();
    }
    if (error.response && error.response.status === 401) {
      resetApp();
    }
    return Promise.reject(error);
  }
);

export default client;
