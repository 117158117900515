import {captureException as capture} from '@sentry/browser';

export const isNumber = (val: any): boolean => typeof val === 'number' && isFinite(val);
export const toNumber = (val: string | number) => isNumber(Number(val)) ? Number(val) : 0;

export const getLocale = (): string => (navigator.languages && navigator.languages[0]) || navigator.language;

export const getLocaleWithoutRegionCode = (): string => getLocale().toLowerCase().split(/[_-]+/)[0];

export function captureException(error: any) {
  let data;

  if (typeof error === 'object') {
    if (error.response) {
      data = JSON.stringify({response: error.response.data});
    } else {
      data = JSON.stringify(error);
    }
  } else {
    data = error;
  }

  if (process.env.STAGE === 'client' || process.env.STAGE === 'alpha') {
    return capture(data);
  } else {
    return console.error(data);
  }
}

export const isParentDomNode = (parent: HTMLElement, children: HTMLElement) => {
  let node = children as Node;

  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node ? node.parentNode : null;
  }
  return false;
};

export const elementHasParentWithClass = (element: Element, classNames: string[]) => {
  const path = [element.className];

  let currentElement = element.parentElement;
  while (currentElement !== null) {
    path.push(currentElement.className);
    currentElement = currentElement.parentElement;
  }

  const allParentClasses = path.join();

  return classNames.some(className => allParentClasses.includes(className));
};

export const getId = (data: any, key?: 'ad' | 'campaign') => {
  if (!data) return null;

  if (key) {
    return data[`${key}.id`];
  }

  if (Object.prototype.hasOwnProperty.call(data, 'ad.id')) {
    return data['ad.id'];
  }

  if (Object.prototype.hasOwnProperty.call(data, 'campaign.id')) {
    return data['campaign.id'];
  }

  return null;
};

export const getStatus = (data: Campaign | Ad): Status => {
  if (!data) return null;

  let status: Status;
  if (Object.prototype.hasOwnProperty.call(data, 'ad.status')) {
    status = (data as Ad)['ad.status'];
  } else if (Object.prototype.hasOwnProperty.call(data, 'campaign.status')) {
    status = (data as Campaign)['campaign.status'];
  }

  return status;
};

export const getEditable = (data: Campaign | Ad): boolean => {
  if (!data) return null;

  let editable = true;
  if (Object.prototype.hasOwnProperty.call(data, 'ad.editable')) {
    editable = (data as Ad)['ad.editable'];
  } else if (Object.prototype.hasOwnProperty.call(data, 'campaign.editable')) {
    editable = (data as Campaign)['campaign.editable'];
  }

  return editable;
};

export function getPattern(min: number, max?: number) {
  if (!isNumber(min)) {
    return '\\d{3,}?';
  }

  const minLen = String(min).split('.')[0].length;
  let maxLen = '';

  if (max && isNumber(max)) {
    maxLen = String(max).split('.')[0].length + '';
  }

  return `\\d{${minLen},${maxLen}}((\\.|,)\\d{0,2})?`;
}

export function flattenTree(nodes: InterestsDict[]) {
  let result: { [key: string]: { id: number, label: string } } = {};

  if (!Array.isArray(nodes) || nodes.length === 0) {
    return result;
  }

  nodes.forEach((node) => {
    result[String(node.id)] = {
      label: node.name,
      id: node.id,
    };

    if (Array.isArray(node.children) && node.children.length > 0) {
      result = Object.assign({}, result, flattenTree(node.children));
    }
  });

  return result;
}

export function range(start: number, end: number, step = 1) {
  return Array.from(
    {length: Math.ceil((end - start + 1) / step)},
    (item, index) => start + index * step
  );
}

export function checkQuery(
  query: string,
  target: any
): boolean {
  if (typeof target === 'string') {
    return target.toLowerCase().includes(query.toLowerCase());
  }

  if (isNumber(target)) {
    return target.toString().toLowerCase().includes(query.toLowerCase());
  }

  if (Array.isArray(target)) {
    return target.some(el => checkQuery(query, el));
  }

  return false;
}

export function validateEmail(value: string | number, skipEmpty?: boolean): boolean {
  const email = value.toString();
  // tslint:disable-next-line:max-line-length
  const rule = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (skipEmpty) {
    return email.length ? rule.test(email.toLowerCase()) : true;
  }
  return rule.test(email.toLowerCase());

}

/**
 * RegExp:
 *  Must be between 8 and 128 chars;
 *  Must be one capital letter, one digit;
 *  Allow: [ { # - + & < / ! ~ * _ ? ^ ` ' : } ; ( | ] > ) % \ @ " , $ .
 */
export function getPasswordAcceptableSymbols(): RegExp {
  return /^[\[\{\#\-\+\&\<\/\!\~\*\_\?\^\=\`\'\:\}\;\(\|\]\>\)\%\\\@\"\ \,\$\.\+A-Za-z\d]+$/;
}

// копипаста регэкспа т.к. строка выбрасывала ошибку и небыло времени разбираться
export function isPasswordValid(password: string): boolean {
  return /^[\[\{\#\-\+\&\<\/\!\~\*\_\?\^\=\`\'\:\}\;\(\|\]\>\)\%\\\@\"\ \,\$\.\+A-Za-z\d]{8,128}$/.test(password);
}

const collator = new Intl.Collator(['en', 'ru'], {numeric: true, sensitivity: 'base'});
export const compareStrings = (valA: string, valB: string, isInverted?: boolean) => {
  if (valA && valB) {
    return collator.compare(valA, valB);
  } else if (valA) {
    return isInverted ? 1 : -1;
  } else if (valB) {
    return isInverted ? -1 : 1;
  }
  return 0;
};

/**
 * сортировка массива объектов по description ( дефолтная сортировка для clients )
 * @param items
 */
export function sortByDescription<T extends { description?: string }>(items: T[]): T[] {
  return items.sort((itemA, itemB) =>
    compareStrings(itemA.description, itemB.description)
  );
}

/**
 * выполнит callback как condition() станет true
 * проверяет раз в checkInterval
 * @param condition - функция, которая возвращает true/false
 * @param callback
 * @param checkInterval
 */
export function waitFor(condition: () => boolean, callback, checkInterval: number = 30) {
  if (!condition()) {
    window.setTimeout(waitFor.bind(null, condition, callback), checkInterval);
  } else {
    callback();
  }
}
