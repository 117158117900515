import { observable, action, runInAction, toJS } from 'mobx';

import observedAction from '~/utils/observedAction';
import { captureException } from '~/utils';

import { fetchAdsByShape, updateAdsStatus, updateAds } from '~/api/ads';
import { appStore, shapesStore, gridStore } from './';

export class AdsStore {
  private queuedCalls: number = 0;
  @observable.shallow collection: {[id: string]: Ad[]} = {};
  @observable loading: boolean = true;

  private isDerivedDataEqualsToPrev<T>(nextData: T, prevData: T): boolean {
    let equals = true;
    let prop: keyof T;

    for (prop in nextData) {
      if (!equals) {
        break;
      }

      if (!(prop in prevData)) {
        equals = false;
        break;
      }

      equals = nextData[prop] === prevData[prop];
    }

    return equals;
  }

  @action.bound
  @observedAction
  reset() {
    this.collection = {};
    this.loading = true;
  }

  @action.bound
  @observedAction
  refreshLocalAds(data: Ad, refresh = true) {
    const shapeId = shapesStore.activeShapeId;

    // It might be just created shape which haven't saved yet
    if (!this.collection[shapeId]) {
      return;
    }

    const index = this.collection[shapeId].findIndex(row => row['ad.id'] === data['ad.id']);

    if (index < 0) {
      return;
    }

    if (this.isDerivedDataEqualsToPrev<Ad>(data, this.collection[shapeId][index])) {
      return;
    }

    this.collection[shapeId][index] = { ...this.collection[shapeId][index], ...data };

    if (!gridStore.gridApi) {
      return;
    }

    if (shapeId === shapesStore.activeShapeId && refresh && gridStore.gridApi) {
      gridStore.gridApi.batchUpdateRowData({ update: [this.collection[shapeId][index]] });
    }
  }

  @action.bound
  @observedAction
  async loadAds(shape: IShape, force = true) {
    if (shape.body.mode !== 'ads') {
      return;
    }
    this.loading = true;
    this.queuedCalls += 1;
    try {
      if (this.collection[shape.id] && this.collection[shape.id].length && !force) {
        return;
      }
      const ads = await fetchAdsByShape(appStore.network, toJS(shape));
      runInAction('loadAds success', () => {
        this.collection[shape.id] = ads;
      });
    } catch (error) {
      runInAction('loadAds fail', () => {
        this.collection[shape.id] = [];
      });

      captureException(error);
    } finally {
      runInAction('loadAds finally', () => {
        this.queuedCalls -= 1;
        if (this.queuedCalls > 0) {
          return;
        }
        this.loading = false;
      });
    }
  }

  @action.bound
  @observedAction
  async updateAdsStatus(ids: number[], status: Status) {
    try {
      const result = await updateAdsStatus(appStore.network, ids, status);
      runInAction('updateAdsStatus success', () => {
        result.forEach((id) => {
          const data: Ad = { 'ad.id': id, 'ad.status': status };
          this.refreshLocalAds(data, false);
        });
      });

      return result;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async updateAds(ads: Ad[]) {
    try {
      const result = await updateAds(appStore.network, ads);

      runInAction('updateAds success', () => {
        result.forEach((id) => {
          const ad: Ad = ads.find(ad => ad['ad.id'] === id);
          const data: Ad = { ...ad };

          this.refreshLocalAds(data);
        });
      });
      return result;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }
}
