import { observable, action, runInAction, toJS } from 'mobx';
import observedAction from '~/utils/observedAction';

import { captureException } from '~/utils';

import { fetchCampaignsByShape, updateCampaigns, updateCampaignsAsync, fetchCampaigns } from '~/api/campaigns';
import { appStore, shapesStore, gridStore, adsStore } from './';

export class CampaignsStore {
  @observable.shallow collection: { [id: string]: Campaign[] } = {};
  @observable loading: boolean = true;

  private jobs: { [job: string]: Campaign } = {};
  private queuedCalls: number = 0;

  timetable: {[id: string]: number} = {};

  constructor() {
  }

  private isDerivedDataEqualsToPrev<T>(nextData: T, prevData: T): boolean {
    let equals = true;
    let prop: keyof T;
    for (prop in nextData) {
      if (!equals) {
        break;
      }

      if (!(prop in prevData)) {
        equals = false;
        break;
      }

      equals = nextData[prop] === prevData[prop];
    }

    return equals;
  }

  @action.bound
  @observedAction
  reset() {
    this.collection = {};
    this.loading = true;
    this.jobs = {};
    this.queuedCalls = 0;
    this.timetable = {};
  }

  @action.bound
  @observedAction
  refreshLocalCampaign(data: Campaign, refreshUI = true) {
    const shapeId = shapesStore.activeShapeId;

    // It might be just created shape which haven't saved yet
    if (!this.collection[shapeId]) {
      return;
    }

    if (shapesStore.activeShape.mode === 'campaigns') {
      const index = this.collection[shapeId].findIndex(row => row['campaign.id'] === data['campaign.id']);

      if (index < 0) {
        return;
      }

      if (this.isDerivedDataEqualsToPrev<Campaign>(data, this.collection[shapeId][index])) {
        return;
      }

      this.collection[shapeId][index] = { ...this.collection[shapeId][index], ...data };

      if (!gridStore.gridApi) {
        return;
      }

      if (shapeId === shapesStore.activeShapeId && refreshUI) {
        gridStore.gridApi.batchUpdateRowData({ update: [this.collection[shapeId][index]] });
      }
    } else if (shapesStore.activeShape.mode === 'ads') {
      const index = adsStore.collection[shapeId].findIndex(row => row['campaign.id'] === data['campaign.id']);

      if (index < 0) {
        return;
      }

      if (this.isDerivedDataEqualsToPrev<Ad>(data as Ad, adsStore.collection[shapeId][index])) {
        return;
      }

      adsStore.collection[shapeId][index] = {
        ...adsStore.collection[shapeId][index],
        'budget.lifetime': data['budget.lifetime'],
        'budget.daily': data['budget.daily'],
        'date.start': data['date.start'],
        'date.stop': data['date.stop'],
        'campaign.bid': data['campaign.bid'],
      };

      if (!gridStore.gridApi) {
        return;
      }

      if (shapeId === shapesStore.activeShapeId && refreshUI && shapesStore.activeShape.mode === 'ads') {
        gridStore.gridApi.batchUpdateRowData({ update: [adsStore.collection[shapeId][index]] });
      }
    }
  }

  @action.bound
  @observedAction
  async loadCampaigns(shape: IShape, force = true) {
    if (shape.body.mode !== 'campaigns') {
      return;
    }
    this.loading = true;
    this.queuedCalls += 1;
    try {
      if (this.collection[shape.id] && this.collection[shape.id].length && !force) {
        return;
      }
      const campaigns = await fetchCampaignsByShape(appStore.network, toJS(shape));
      runInAction('loadCampaigns success', () => {
        const now = new Date();
        now.setMilliseconds(0);
        now.setSeconds(0);
        this.timetable[shape.id] = now.getTime();
        this.collection[shape.id] = campaigns;
      });

    } catch (error) {
      runInAction('loadCampaigns fail', () => {
        this.collection[shape.id] = [];
      });

      captureException(error);
    } finally {
      runInAction('loadCampaigns finally', () => {
        this.queuedCalls -= 1;
        if (this.queuedCalls > 0) {
          return;
        }
        this.loading = false;
      });
    }
  }

  @action.bound
  @observedAction
  async fetchHourlyStat(campaigns: Campaign[]) {
    try {
      const campaignsToUpdate: number[] = [];

      campaigns.forEach((campaign) => {
        if (!('statistics.general.hourly' in campaign)) {
          campaignsToUpdate.push(campaign['campaign.id']);
        }
      });

      if (!campaignsToUpdate.length) {
        return;
      }

      const result = await fetchCampaigns({
        network: appStore.network,
        campaign_ids: campaignsToUpdate,
        fields: ['campaign.id', 'statistics.general.hourly'],
      });

      const data = result.data.result.campaigns;

      runInAction('fetchHourlyStat success', () => {
        data.forEach((campaign: Campaign) => {
          this.refreshLocalCampaign({
            'campaign.id': campaign['campaign.id'],
            'statistics.general.hourly': [],
            ...campaign,
          });
        });
      });
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async updateCampaignsStatus(ids: number[], status: Status) {
    try {
      const campaigns = this.collection[shapesStore.activeShapeId]
        .filter(campaign => ids.includes(campaign['campaign.id']))
        .map(campaign => ({ 'campaign.id': campaign['campaign.id'], 'campaign.status': status }));

      const result = await updateCampaigns(appStore.network, campaigns);
      runInAction('updateCampaignsStatus success', () => {
        result.forEach((id) => {
          this.refreshLocalCampaign({ 'campaign.id': id, 'campaign.status': status }, false);
        });
      });
      return result;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async updateCampaignShedule(id: number, shedule: { start?: ISODate, stop?: ISODate }) {
    try {
      const campaign: Campaign = { 'campaign.id': id };

      if (shedule.start !== undefined) {
        campaign['date.start'] = shedule.start;
      }

      if (shedule.stop !== undefined) {
        campaign['date.stop'] = shedule.stop;
      }

      const result = await updateCampaigns(appStore.network, [campaign]);
      runInAction('updateCampaignShedule success', () => {
        result.forEach((id) => {
          const data: Campaign = { 'campaign.id': id };
          if (shedule.start !== undefined) data['date.start'] = shedule.start;
          if (shedule.stop !== undefined) data['date.stop'] = shedule.stop;

          this.refreshLocalCampaign(data);
        });
      });
      return result;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async updateCampaignBudget(id: number, lifetime: number, daily: number, mixing: Mixing) {
    try {
      const campaign: Campaign = { 'campaign.id': id };

      campaign['budget.lifetime'] = lifetime;
      campaign['budget.daily'] = daily;
      campaign['budget.mixing'] = mixing;

      const result = await updateCampaigns(appStore.network, [campaign]);
      runInAction('updateCampaignBudget success', () => {
        result.forEach((id) => {
          const data: Campaign = { 'campaign.id': id };
          data['budget.lifetime'] = lifetime;
          data['budget.daily'] = daily;
          this.refreshLocalCampaign(data);
        });
      });
      return result;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async updateCampaignRate(id: number, bid: number) {
    try {
      const campaign: Campaign = { 'campaign.id': id };
      campaign['campaign.bid'] = bid;

      const result = await updateCampaigns(appStore.network, [campaign]);
      runInAction('updateCampaignRate success', () => {
        result.forEach((id) => {
          const data: Campaign = { 'campaign.id': id };
          data['campaign.bid'] = bid;
          this.refreshLocalCampaign(data);
        });
      });
      return result;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async updateCampaigns(campaigns: Campaign[]) {
    try {
      const jobs = await updateCampaignsAsync(appStore.network, campaigns);

      runInAction('updateCampaigns success', () => {
        campaigns.forEach((campaign, index) => {
          const job_id = jobs[index];
          this.jobs[job_id] = campaign;
        });
      });
      return jobs;
    } catch (error) {
      captureException(error);
      throw new Error(error);
    }
  }
}
