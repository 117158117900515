import client from './httpClient';

export const fetchShapes = (network: Network) => {
  return new Promise<IShape[]>((resolve, reject) => client.post('/tab/get', { network })
    .then((res) => {
      if (res.data.result.tabs.length) {
        const collection: IShape[] = res.data.result.tabs.map((shape: { body: string }) => ({
          ...shape,
          body: JSON.parse(shape.body),
        }));

        resolve(collection);
      } else {
        resolve([]);
      }
    })
    .catch(error => reject(error))
  );
};

export const createShape = (network: Network, shape: IShape) => {
  return new Promise<string>((resolve, reject) => client.post(
    'tab/create',
    {
      network,
      name: shape.name,
      body: JSON.stringify(shape.body),
      idx: shape.index,
    }
  )
    .then((res) => {
      if (res.data.success === true) {
        resolve(res.data.id);
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error))
  );
};

export const updateShape = (network: Network, shape: IShape) => {
  return new Promise((resolve, reject) => client.post(
    'tab/update',
    {
      name: shape.name,
      body: JSON.stringify(shape.body),
      id: shape.id,
      idx: shape.index,
    }
  )
    .then((res) => {
      if (res.data.success === true) {
        resolve();
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error))
  );
};

export const deleteShape = (network: Network, id: string) => {
  return new Promise((resolve, reject) => client.post('tab/delete', { id })
    .then((res) => {
      if (res.data.success === true) {
        resolve();
      } else {
        reject(new Error(JSON.stringify(res)));
      }
    })
    .catch(error => reject(error))
  );
};
