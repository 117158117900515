import {observable, action, computed, runInAction} from 'mobx';

import {formatMessage, changeLocale} from '~/intlProvider';

import {captureException} from '~/utils';
import observedAction from '~/utils/observedAction';

import {fetchUser, updateUser, checkUserExists, changePassword} from '~/api/user';

import {authStore, notificationsStore} from './';

export class UserStore {
  @observable user: User = null;
  @observable loading: boolean = false;

  @computed get name() {
    return this.user && `${this.user.fname} ${this.user.lname}`;
  }

  @computed get fullName() {
    return this.user && `${this.user.fname} ${this.user.lname} ${this.user.sname}`;
  }

  @action.bound
  @observedAction
  async fetchUser() {
    if (!authStore.isAuthenticated) {
      return;
    }

    try {
      const user = await fetchUser();
      runInAction('fetchUser success', () => {
        if (user.locale) {
          changeLocale(user.locale);
        }
        this.user = user;
      });
    } catch (error) {
      captureException(error);
      // показать ошибку
      document.getElementById('root').style.display = 'none';
      document.querySelector('#error-container').removeAttribute('style');
    }
  }

  @action.bound
  @observedAction
  async checkUserExists(id: string) {
    try {
      const names = await checkUserExists(id);

      let fullName = '';
      if (names) {
        const namesList = [names.lname, names.fname, names.sname];
        fullName = namesList.filter(_ => _).join(' ');
      }
      return fullName;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return null;
      }

      captureException(error);
    }
  }

  @action.bound
  @observedAction
  async updateUser(user: Partial<User>, showNotification: boolean = true) {
    if (!authStore.isAuthenticated || !this.user) {
      return;
    }
    this.loading = true;
    try {
      await updateUser(user);

      runInAction('updateUser success', () => {
        if (user.locale && this.user.locale !== user.locale) {
          changeLocale(user.locale);
        }

        this.user = {...this.user, ...user};
        if (showNotification) {
          notificationsStore.add({
            type: 'success',
            message: formatMessage({id: 'app.profile.update_success'}),
          });
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction('updateUser fail', () => {
        notificationsStore.add({
          type: 'error',
          message: formatMessage({id: 'app.profile.update_error'}),
        });
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  async changePassword(passwords: UserPasswords) {
    this.loading = true;
    try {
      await changePassword(passwords);

      runInAction('changePassword success', () => {
        notificationsStore.add({
          type: 'success',
          message: formatMessage({id: 'app.profile.password_change_success'}),
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction('changePassword fail', () => {
        if (error.response && error.response.status === 400) {
          notificationsStore.add({
            type: 'warning',
            message: 'Указан неверный пароль',
          });
        } else if (error.response && error.response.status === 401) {
          notificationsStore.add({
            type: 'warning',
            message: formatMessage({id: 'app.profile.old_password_wrong'}),
          });
        } else if (error.response && error.response.status === 422) {
          notificationsStore.add({
            type: 'warning',
            message: formatMessage({id: 'app.profile.passwords_different'}),
          });
        } else {
          notificationsStore.add({
            type: 'error',
            message: formatMessage({id: 'app.profile.password_change_error'}),
          });
        }
        this.loading = false;
      });
      captureException(error);
      throw new Error(error);
    }
  }

  @action.bound
  @observedAction
  reset() {
    this.user = null;
  }
}
