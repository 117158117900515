import { IntlProvider, addLocaleData, InjectedIntl } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import dayjs from 'dayjs';
import localeData from './messages.json';

import { getLocaleWithoutRegionCode } from './utils';

addLocaleData([...en, ...ru]);

const locale = getLocaleWithoutRegionCode() || 'en';
const messages = localeData[locale];

let { intl } = new IntlProvider({ locale, messages }, {}).getChildContext();

export function changeLocale(newLocale: 'ru' | 'en') {
  dayjs.locale(newLocale);
  intl = new IntlProvider({ locale: newLocale, messages: localeData[newLocale] }, {}).getChildContext().intl;
}
export function resetLocale() {
  intl = new IntlProvider({ locale, messages }, {}).getChildContext().intl;
}
export function getLocale() {
  return intl.locale;
}

export const formatMessage: InjectedIntl['formatMessage'] = (...args) => intl.formatMessage(...args);
export const formatDate: InjectedIntl['formatDate'] = (...args) => intl.formatDate(...args);
export const formatNumber: InjectedIntl['formatNumber'] = (...args) => {
  if (args[0] === undefined) {
    args[0] = 0;
  }
  return intl.formatNumber(...args);
};
export const formatTime: InjectedIntl['formatTime'] = (...args) => intl.formatTime(...args);

export function getIntl() {
  return intl;
}
