export const campaignsFields: ShapeField[] = [
  {
    name: undefined,
    path: 'selection',
    local: true,
    index: 0,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'settings',
    local: true,
    index: 1,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'status',
    local: true,
    index: 2,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'campaign',
    local: true,
    index: 3,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'ads',
    local: true,
    index: 4,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
    math_allowed: true,
    math_default: 'avg',
  },
  {
    name: undefined,
    path: 'bid',
    local: true,
    index: 5,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'schedule',
    local: true,
    index: 6,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'budget',
    local: true,
    index: 7,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'statistics.general.spent',
    local: true,
    index: 8,
    type: 'currency',
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'sum',
    position: 'right',
  },
  {
    name: undefined,
    path: 'statistics.general.impressions',
    local: true,
    index: 9,
    type: 'int',
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'sum',
    position: 'right',
  },
  {
    name: undefined,
    path: 'statistics.general.clicks',
    local: true,
    index: 10,
    type: 'int',
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'sum',
    position: 'right',
  },
  {
    name: undefined,
    path: 'statistics.general.ctr',
    local: true,
    index: 11,
    type: 'percent',
    round_digits: 3,
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'avg',
    position: 'right',
  },
];

export const adsFields: ShapeField[] = [
  {
    name: undefined,
    path: 'selection',
    local: true,
    index: 0,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'settings',
    local: true,
    index: 1,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'status',
    local: true,
    index: 2,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'campaign',
    local: true,
    index: 3,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'ad-status',
    local: true,
    index: 4,
    width: 24,
    pinned: 'left',
    type: undefined,
    parent: undefined,
    position: undefined,
  },
  {
    name: undefined,
    path: 'ad',
    local: true,
    index: 5,
    type: undefined,
    parent: 'groups.metafields.mt_banner_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'bid',
    local: true,
    index: 6,
    type: undefined,
    parent: 'groups.metafields.mt_campaign_base',
    position: undefined,
  },
  {
    name: undefined,
    path: 'statistics.general.spent',
    local: true,
    index: 7,
    type: 'currency',
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'sum',
    position: 'right',
  },
  {
    name: undefined,
    path: 'statistics.general.impressions',
    local: true,
    index: 8,
    type: 'int',
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'sum',
    position: 'right',
  },
  {
    name: undefined,
    path: 'statistics.general.clicks',
    local: true,
    index: 9,
    type: 'int',
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'sum',
    position: 'right',
  },
  {
    name: undefined,
    path: 'statistics.general.ctr',
    local: true,
    index: 10,
    type: 'percent',
    round_digits: 3,
    parent: 'groups.metrics.statistics',
    math_allowed: true,
    math_default: 'avg',
    position: 'right',
  },
];
