import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { init } from '@sentry/browser';
import { BrowserRouter, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ru';

import { getLocaleWithoutRegionCode } from './utils';

import App from './containers/App';
import * as stores from './stores';

import '@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import 'pds/dist/lib/styles/index.css';
import './styles/index.css';

if (process.env.STAGE === 'client') {
  init({
    dsn: 'https://817ae78996cd48dd88fe6b3512b8e999@sentry.plarin.net/10',
    debug: true,
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === 'ui.click' ? null : breadcrumb;
    },
  });
}

if (process.env.STAGE === 'development') {
  init({
    dsn: 'https://f6a5d4d62852406cb356da0ad54c2158@sentry.plarin.net/9',
    debug: true,
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === 'ui.click' ? null : breadcrumb;
    },
  });
}

if (process.env.STAGE === 'alpha') {
  init({
    dsn: 'https://ff89bc88c5b84e9aa85b56d824aec8e7@sentry.plarin.net/8',
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === 'ui.click' ? null : breadcrumb;
    },
  });
}

configure({
  enforceActions: 'observed',
});

const language = getLocaleWithoutRegionCode();

dayjs.locale(language);
dayjs.extend(localeData);

const preloader = document.getElementById('preloader');
preloader && preloader.remove();

// hide React Intl missing messages warnings
if (process.env.NODE_ENV !== 'production') {
  const originalConsoleError = console.error;
  if (console.error === originalConsoleError) {
    console.error = (...args: any) => {
      if (args[0] && typeof args[0].indexOf === 'function' && args[0].indexOf('[React Intl] Missing message:') === 0) {
        return;
      }
      originalConsoleError.call(console, ...args);
    };
  }
}

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter basename={'/'}>
      <Route component={App} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
