/**
 * сбрасывает все stores в приложении, сбрасывает locale и при удаляет токены авторизации
 */
import {EXPIRE_TOKEN} from '~/constants';
import {appStore} from '~/stores';
import {resetLocale} from '~/intlProvider';

export function resetApp() {
  localStorage.removeItem(EXPIRE_TOKEN);
  sessionStorage.removeItem(EXPIRE_TOKEN);
  appStore.reset();
  resetLocale();
}
