import { observable, action, computed, runInAction, ObservableMap } from 'mobx';

import observedAction from '~/utils/observedAction';
import { captureException } from '~/utils';
import { STATUS } from '~/constants';

import { fetchShapes, deleteShape } from '~/api/shapes';

import generateShape from '~/utils/generateShape';
import fieldIndex from '~/utils/fieldIndex';

import { appStore, metricsStore } from './';
import Shape from './Shape';

export class ShapesStore {
  // Use it for save id of shape which want to delete or hide
  tempId: string;

  @observable activeShapeId: string;
  @observable collection: ObservableMap<string, Shape>;
  @observable loading: boolean;

  constructor() {
    this.collection = observable.map(new Map());
    this.loading = true;
  }

  private findClosestNotHiddenShape(id: string, direction: 'next' | 'prev'): Shape {
    if (this.collection.size === 0) {
      return undefined;
    }

    let index = this.collection.get(id).index;

    if (direction === 'next') {
      index += 1;
    }

    if (direction === 'prev') {
      index -= 1;
    }

    if (index < 0 || index > this.collection.size - 1) {
      return undefined;
    }

    let target: Shape;

    this.collection.forEach((shape) => {
      if (shape.index === index) {
        target = shape;
      }
    });

    if (!target) {
      return undefined;
    }

    if (target.hidden) {
      return this.findClosestNotHiddenShape(target.id, direction);
    }

    return target;
  }

  private findLastNotHiddenShape(prevIndex?: number): Shape {
    if (this.collection.size === 0) {
      return undefined;
    }

    const index = prevIndex ? prevIndex - 1 : this.collection.size - 1;

    if (index < 0 || index > this.collection.size - 1) {
      return undefined;
    }

    let target: Shape;

    this.collection.forEach((shape) => {
      if (shape.index === index) {
        target = shape;
      }
    });

    if (!target) {
      return undefined;
    }

    if (target.hidden) {
      return this.findLastNotHiddenShape(target.index);
    }

    return target;
  }

  private isEveryShapeHidden() {
    const length = this.collection.size;
    let i = 0;

    this.collection.forEach((shape: Shape) => {
      if (shape.hidden) {
        i += 1;
      }
    });

    return i === length;
  }

  private recalculateIndexes() {
    const sortedIndexes: { [key: string]: number } = {};

    Array.from(this.collection.values())
      .sort((a: Shape, b: Shape) => a.index - b.index)
      .map((shape: Shape, index: number) => sortedIndexes[shape.id] = index);

    this.collection.forEach(shape => shape.setIndex(sortedIndexes[shape.id]));
  }

  @action.bound
  @observedAction
  reset() {
    this.collection.clear();
    this.loading = true;
    this.activeShapeId = undefined;
  }

  @action.bound
  @observedAction
  afterShapeSave(prevId: string, nextId: string) {
    this.collection.set(nextId, this.collection.get(prevId));
    this.activeShapeId = nextId;
    this.collection.delete(prevId);
  }

  @computed get activeShape(): Shape {
    if (!this.activeShapeId) return null;

    return this.collection.get(this.activeShapeId);
  }

  @action.bound
  @observedAction
  setTempId(id: string) {
    this.tempId = id;
  }

  @action.bound
  @observedAction
  async loadShapes() {
    this.loading = true;
    try {
      const collection = await fetchShapes(appStore.network);

      runInAction('loadShapes success', () => {
        const shapes: IShape[] = collection.map((shape, index) => {
          return Object.assign(
            { modified: false, unsaved: false, wizard: false },
            { index },
            shape
          );
        });

        shapes.forEach(shape => this.collection.set(shape.id, new Shape(shape)));

        if (shapes.length && shapes.some(shape => !shape.body.hidden)) {
          this.activeShapeId = this.findLastNotHiddenShape().id;
        } else {
          const wizard = Boolean(shapes.length);
          this.addLocalShape(wizard);
        }
      });
    } catch (error) {
      runInAction('loadShapes fail', () => {
        this.addLocalShape(false);
      });

      captureException(error);
    } finally {
      runInAction('loadShapes finally', () => {
        if (this.activeShape) {
          this.activeShape.loadData();
        }

        this.loading = false;
      });
    }
  }

  @action.bound
  @observedAction
  addLocalShape(wizard: boolean = true, shape: IShape = generateShape(wizard)) {
    const defaultPreset = [
      'statistics.general.fast',
      'statistics.general.cpm',
      'statistics.general.cpc',
      'statistics.general.reach',
      'statistics.general.reach_frequency',
      'statistics.general.reach_percent',
      'statistics.general.target_audience',
      'statistics.financial.revenue',
      'statistics.financial.roi',
    ];

    const fields = Array.from(shape.body.campaigns.fields);

    const metrics = metricsStore.campaigns;

    defaultPreset.forEach((path: string) => {
      const targetGroup = metrics.find(group => group.items.some(field => field.path === path));
      const field = targetGroup.items.find(field => field.path === path);
      const index = fieldIndex(path, targetGroup.id, fields, metricsStore.campaigns);
      const newField: ShapeField = {
        ...field,
        name: undefined,
        parent: targetGroup.id,
        index,
      };

      fields.splice(index, 0, newField);
    });

    const sortedFields = fields.map((field, index) => ({ ...field, index }));
    shape.body.campaigns.fields = sortedFields;
    shape.index = this.collection.size;

    this.collection.set(shape.id, new Shape(shape));

    this.activeShapeId = shape.id;
  }

  @action.bound
  @observedAction
  switchShape(id: string, index?: number) {
    if (id === this.activeShapeId) return;

    this.activeShapeId = id;

    if (index) {
      this.activeShape.setIndex(index);
      this.recalculateIndexes();
    }

    this.activeShape.loadData(false);
  }

  @action.bound
  @observedAction
  async deleteShape(id: string = this.tempId || this.activeShapeId) {
    if (this.activeShapeId === id) {
      let shape = this.findClosestNotHiddenShape(id, 'prev');

      if (!shape) {
        shape = this.findClosestNotHiddenShape(id, 'next');
      }

      if (shape) {
        this.switchShape(shape.id);
      }
    }

    const unsaved = this.collection.get(id).unsaved;
    this.collection.delete(id);

    if (!this.collection.size || this.isEveryShapeHidden()) {
      this.addLocalShape();
    }

    if (unsaved) {
      return;
    }

    try {
      await deleteShape(appStore.network, id);
    } catch (error) {
      captureException(error);
    }
  }

  @action.bound
  @observedAction
  hideShape(id: string = this.activeShapeId) {
    this.collection.get(id).setHidden(true);
    this.collection.get(id).save();

    if (this.collection.size === 1 || this.isEveryShapeHidden()) {
      this.addLocalShape();
    }

    if (this.activeShapeId === id) {
      let shape = this.findClosestNotHiddenShape(id, 'prev');

      if (!shape) {
        shape = this.findClosestNotHiddenShape(id, 'next');
      }

      this.switchShape(shape.id);
    }
  }

  @action.bound
  @observedAction
  showShape(id: string) {
    this.collection.get(id).setHidden(false);

    const lastShapeID = this.findLastNotHiddenShape();

    if (lastShapeID) {
      this.switchShape(id, lastShapeID.index + 1);
    } else {
      this.switchShape(id, 0);
    }

    this.collection.get(id).save();
  }

  @action.bound
  @observedAction
  async openCampaignAds(campaign?: Campaign) {
    const shapeName = `${campaign['campaign.name']} (${campaign['campaign.id']})`;

    for (const entry of this.collection) {
      const shape = entry[1];

      // const sameName = shape.name === shapeName;
      const sameCampaign = shape.campaignIds.length === 1 && shape.campaignIds[0] === campaign['campaign.id'];
      const isAdsActive = shape.mode === 'ads';

      if (sameCampaign && isAdsActive) {
        this.switchShape(shape.id);
        return;
      }
    }

    const statuses: Status[] = [STATUS.active, STATUS.paused];

    if (campaign['ads.archived'] && campaign['ads.archived'] > 0) {
      statuses.push(STATUS.archieved);
    }

    const shape = generateShape(false);
    shape.name = shapeName;
    shape.body.mode = 'ads';
    shape.body.campaignIds = [campaign['campaign.id']];
    shape.body.ads.status = statuses;

    this.addLocalShape(false, shape);

    await this.activeShape.save();
    // this.switchShape(this.activeShapeId);
  }
}
