import { AdsStore } from './adsStore';
import { NotificationsStore } from './notificationsStore';
import { AppStore } from './appStore';
import { AuthStore } from './authStore';
import { UserStore } from './userStore';
import { CampaignsStore } from './campaignsStore';
import { ClientsStore } from './clientsStore';
import { PaymentStore } from './paymentStore';
import { OrganizationStore } from './organizationStore';
import { MetricsStore } from './metricsStore';
import { ShapesStore } from './shapesStore';
import { MenusStore } from './menusStore';
import { GridStore } from './gridStore';
import { ResourcesStore } from './resourcesStore';
import {StatementStore} from '~/stores/statementStore';

export const appStore = new AppStore();
export const notificationsStore = new NotificationsStore();

export const authStore = new AuthStore();
export const userStore = new UserStore();

export const adsStore = new AdsStore();
export const campaignsStore = new CampaignsStore();
export const clientsStore = new ClientsStore();
export const paymentStore = new PaymentStore();
export const organizationStore = new OrganizationStore();
export const metricsStore = new MetricsStore();
export const shapesStore = new ShapesStore();
export const menusStore = new MenusStore();
export const resourcesStore = new ResourcesStore();
export const statementStore = new StatementStore();

export const gridStore = new GridStore();
