let API_URL: string = `${window.location.host}/api/v1/`;
let API_PROTOCOL: string = 'https';

// switch (process.env.STAGE) {
//   case 'alpha':
//     API_URL = 'api-alpha.plarin.net';
//     break;
//   case 'api-development':
//     API_URL = '0.0.0.0:5000';
//     API_PROTOCOL = 'http';
//     break;
//   case 'client':
//     API_URL = 'api-stable.plarin.net';
//     break;
//   default:
//     API_URL = 'api-stage.plarin.net';
//     break;
// }

export { API_URL };
export { API_PROTOCOL };

export const EXPIRE_TOKEN: string = '__pl_token_expire';
export const SIDEBAR_COLLAPSED: string = 'is_sidebar_collapsed';

export const STATUS: { [key: string]: Status } = {
  paused: 0,
  active: 1,
  archieved: 2,
};

export const MOUSE_LEAVE_HIDE_DELAY: number = 600;
export const TAB_MINIMAL_WIDTH: number = 100;

export const MINIMAL_ALLOWED_DAY: string = '2013-01-01';

export const PATHS = {
  signIn: '/login',
  vk: '/vk',
  mt: '/mt',
  dashboards: '/dashboards',
  yandex: '/yandex',
  facebook: '/facebook',
  creatives_mt_formats: '/creatives_mt_formats',
  test: '/test',
};

export const HEADER_ROW_HEIGHT = 25;
export const ROW_HEIGHT = 35;
export const FOOTER_ROW_HEIGHT = 40;

export const TOTAL_HOURS_OF_WEEK = 168;

export const EVENT_MOUSE_ENTER = 'mouseEnter';
export const EVENT_MOUSE_LEAVE = 'mouseLeave';
export const REFRESH_TOKEN_PATH = 'refresh';
